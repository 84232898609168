.sitesearch-hero {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 50px 20px 0;
  position: relative;
  padding-bottom: 25px;

  @include breakpoint(sm) {
    margin: 70px 20px 0;
  }

  &__wrapper {
    margin: auto;
    padding-top: 5px;
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 566px;
    width: 100%;
    justify-content: space-between;
  }

  &__logo {
    height: 55px;
    margin-bottom: 30px;

    @include breakpoint(sm) {
      height: 92px;
    }
  }

  &__overline {
    margin-bottom: 10px;
  }

  &__button {
    margin-bottom: 15px;
  }

  &__caption {
    font-size: 15px;
    line-height: 19px;
    color: #999999;
    text-align: center;

    &-text {
      display: inline-block;
    }

    &-link {
      border-bottom: none;
      color: #999999;
      font-weight: bold;
    }
  }
}
