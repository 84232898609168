.lp-logo-decoration {
  position: absolute;
  display: none;
  top: 0;
  left: 50%;
  margin-left: -280px;
  width: 560px;
  height: 150px;
  overflow: hidden;

  @include breakpoint(lg) {
    display: block;
  }

  svg {
    width: 560px;
    height: 560px;
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
  }
}
