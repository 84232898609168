.widget-stock {
  &__header {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;

    @include breakpoint(sm) {
      margin-bottom: 35px;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &__topic {
    font-size: $font-text-base;
    font-weight: 500;
    color: $color-purple;

    @include breakpoint(sm) {
      font-size: $font-text-sm;
    }
  }

  &__description {
    font-size: $font-text-base;
    font-weight: 300;
    color: $color-charcoal-dark;
    margin-bottom: 15px;

    @include breakpoint(sm) {
      font-size: $font-text-sm;
    }
  }

  &__details {
    display: flex;
    align-items: center;
    position: relative;

    &.rising {
      color: $color-green;
    }

    &.falling {
      color: $color-red;
    }
  }

  &__number {
    font-size: 2.5rem;
    z-index: 1;
    font-weight: 300;
    color: $color-purple;
    margin: 0;

    @include breakpoint(sm) {
      font-size: 3.75rem;
    }
  }

  &__symbol {
    display: block;
    position: relative;
    z-index: 1;

    &,
    svg {
      width: 42px;
      height: 42px;
      fill: currentColor;

      @include breakpoint(sm) {
        width: 60px;
        height: 60px;
      }
    }

    .falling > & {
      transform: rotate(180deg);
    }
  }

  &__additional-details {
    font-size: 1rem;
    margin-left: 10px;

    @include breakpoint(sm) {
      font-size: 1.2rem;
    }
  }

  &__additional-change {
    font-weight: 500;
    margin: 0;
  }

  &__additional-percentage {
    font-weight: 300;
    margin: 0;
  }

  &__meta {
    display: flex;
    flex-direction: column-reverse;
    color: $color-middlegray;
    line-height: 1.8;

    @include breakpoint(sm) {
      flex-direction: row;
      justify-content: space-between;
      line-height: 1.4;
    }
  }
}
