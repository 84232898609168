.logo-row {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 0 20px;
  position: relative;
  padding-bottom: 25px;

  &__wrapper {
    max-width: 988px;
    width: 100%;
  }

  &__overline {
    text-align: center;
    margin-bottom: 35px;
  }

  &__logo-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include breakpoint(sm) {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
    }

    @include breakpoint(lg) {
      justify-content: space-between;
    }
  }

  &__logo {
    height: 30px;
    width: auto;
    max-width: none;
    margin-bottom: 40px;

    @include breakpoint(sm) {
      width: 33%;
      margin-bottom: 30px;
    }

    @include breakpoint(lg) {
      width: auto;
    }

    &--eathappy {
      height: 52px;
    }
  }
}
