.lp-checklist {
  @include list-reset;

  display: flex;
  flex-wrap: wrap;
  font-weight: 500;
  font-size: 0.75rem;
  justify-content: center;
  margin: 4rem 0;

  @include breakpoint(md) {
    font-size: 1rem;
  }

  &__item {
    display: flex;
    align-items: center;
    margin: 0.5rem;

    svg {
      margin-right: 0.5rem;
    }
  }
}
