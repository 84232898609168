// List Element zurücksetzen.
//
// ul.example {
//   @include list-reset;
// }
@mixin list-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}
