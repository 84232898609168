.toolbar {
  position: fixed;
  width: auto;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  padding: 1rem 0;
  transition: bottom 0.2s ease-in-out;
  z-index: $z-index-toolbar;

  @include breakpoint(lg) {
    top: calc(#{$search-bar-height-desktop} + 4rem);
    bottom: auto;
    transform: translateX(-475px);
    padding: 0;
  }

  &--fullscreen {
    @include breakpoint(lg) {
      left: 1.5rem;
      transform: none;
    }
  }

  &.hide {
    bottom: calc(-#{$search-bar-height-mobile} - 2rem);
  }

  &__wrapper {
    display: flex;
    justify-content: center;
  }

  &__nav {
    @include list-reset;
    @include var(background-color, bg-search-results);

    flex: 0 1 auto;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-radius: $border-radius-l;
    box-shadow: $shadow-searchfield;
    border: 1px solid $color-lightgrey-1;
    height: $search-bar-height-mobile;

    @include breakpoint(lg) {
      box-shadow: none;
      border: none;
      flex-direction: column;
      height: auto;
      width: $search-bar-height-mobile;
    }
  }

  &__label {
    position: absolute;
    bottom: -0.5rem;
    left: 50%;
    transform: translateX(-50%);
    font-size: 0.8rem;
    padding: 0.25rem 0.5rem;
    display: none;
  }

  &__icon {
    &,
    svg {
      display: block;
    }

    path {
      fill: $color-charcoal-dark;
    }
  }

  &__nav-item {
    position: relative;
    padding: 0 1rem;
    height: $search-bar-height-mobile;
    display: flex;
    align-items: center;
    border-radius: $border-radius-l;

    @media screen and (min-width: 400px) {
      padding: 0 1.5rem;
    }

    @include breakpoint(lg) {
      padding: 0;
      width: $search-bar-height-mobile;
      margin-bottom: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &:hover {
      path {
        fill: $color-purple;
      }

      .toolbar__label {
        @include breakpoint(lg) {
          display: block;
        }
      }
    }

    &.active {
      background-color: $color-lightgrey-3;

      path {
        fill: $color-purple;
      }

      &:hover {
        .toolbar__label {
          display: none;
        }
      }
    }

    &.has-top-border {
      @include breakpoint(lg) {
        position: relative;
        margin-top: 1rem;

        &::before {
          content: "";
          border-top: 1px solid $color-middlegray-2;
          width: 100%;
          height: 0;
          display: block;
          position: absolute;
          top: -1rem;
          left: 0;
        }
      }
    }
  }

  &__nav-link {
    @include var(color, text-01-color);
  }
}
