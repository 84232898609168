.alert {
  padding: 24px;
  background-color: $color-white;
  border-radius: $border-radius-sm;

  &--info {
    background-color: $color-azure;
    color: $color-white;
  }

  &--success {
    background-color: $color-green;
    color: $color-white;
  }

  &--error {
    background-color: $color-red;
    color: $color-white;
  }
}
