.form-checkbox {
  position: relative;

  input[type="checkbox"] {
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    opacity: 0.00001;
    cursor: pointer;

    & + label {
      display: block;
      cursor: pointer;

      .checkbox-icon {
        display: block;
      }
    }

    &:focus + label .checkbox-icon,
    &:focus-visible + label .checkbox-icon,
    &:focus-within + label .checkbox-icon {
      outline-offset: 1px;
      outline-style: dashed;
      outline-width: 1px;
      outline-color: $color-azure;
    }

    &:hover + label .checkbox-icon-panel {
      fill: $color-lightgrey-1;
    }

    &:hover + label .checkbox-icon-check {
      stroke: $color-lightgrey-1;
    }

    &:checked + label {
      .checkbox-icon-panel {
        stroke: $color-azure;
        fill: $color-azure;
      }
    }

    &:checked:hover + label .checkbox-icon-panel {
      fill: $color-purple;
    }
  }
}
