html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: $font;
  height: 100%;
  scroll-behavior: smooth;
  font-size: $font-size-base;
}

body {
  color: $color-charcoal-dark;
  background-color: $color-white;
  height: auto;
}

header,
main,
footer {
  display: block;
}
