.pills {
  @include list-reset;

  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  &__pill {
    background-color: $color-white;
    border-radius: $border-radius-ssm;
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    margin: 0.25rem;
    color: $color-purple;
    font-weight: 400;
    text-align: center;
    justify-content: center;

    &:hover {
      color: $color-charcoal-dark;
      text-decoration: none;
    }

    &:active,
    &:focus {
      outline: none;
      text-decoration: none;
      box-shadow: $shadow-searchfield;
    }

    svg {
      margin-right: 0.25rem;
    }

    &--transparent {
      font-weight: 400;
      color: $color-middlegray-3;
      background-color: transparent;

      &:hover,
      &:active,
      &:focus {
        color: $color-charcoal-dark;
      }
    }
  }
}

.pill {
  @include transition(all 0.3s ease);

  background-color: $color-white;
  border-radius: $border-radius-ssm;
  display: inline-flex;
  align-items: center;
  padding: 15px 20px;
  margin: 5px 0;
  color: $color-charcoal-dark;
  justify-content: center;
  font-weight: 500;
  width: 100%;

  @include breakpoint(sm) {
    width: auto;
    margin: 5px 10px 5px 0;
  }
}

a.pill {
  &:hover {
    background-color: $color-lightgrey-1;
    color: $color-charcoal-dark;
    text-decoration: none;
  }

  &:active,
  &:focus {
    outline: none;
    text-decoration: none;
    box-shadow: $shadow-searchfield;
  }
}
