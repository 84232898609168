.teaser {
  $teaser: &;

  --swipe: 0;

  position: relative;
  background-color: $color-white;
  border-radius: $border-radius-sm;
  padding: 15px;
  margin-bottom: 10px;

  @include breakpoint(md) {
    margin-bottom: 20px;
  }

  &--has-mobile-gestures {
    @media (hover: none) {
      padding: 0;
      overflow: hidden;
    }
  }

  &--transparent {
    background-color: transparent;
    border-radius: 0;
    padding: 0;
  }

  &.has-logo-deco {
    overflow: hidden;

    @include breakpoint(sm) {
      padding-right: 15%;
    }

    & > * {
      position: relative;
      z-index: 1;
    }

    &::before {
      content: "";
      width: 50%;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      background-image: url("../../images/logo-nona.svg");
      background-size: auto 150%;
      background-position: left center;
      z-index: 0;
      opacity: 0.4;

      @include breakpoint(sm) {
        width: 14%;
        opacity: 1;
      }
    }
  }

  &__close {
    svg {
      display: block;

      path {
        fill: $color-middlegray-3;
      }
    }
  }

  &__content {
    flex: 0 1 100%;
  }

  &__image-wrapper {
    flex: 1 1 155px;
    position: relative;
    overflow: hidden;
  }

  &__image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    object-fit: cover;
  }

  &__image-meta {
    position: absolute;
    bottom: 0.5rem;
    right: 0.5rem;
  }

  &__image-meta-info {
    border-radius: $border-radius-s;
    background-color: rgba(255, 255, 255, 0.9);
    color: $color-charcoal-dark;
    padding: 0.25rem;
    font-size: $font-text-xxs;
    display: block;
  }

  &__topline {
    display: block;
    line-height: 1.1;
    color: $color-salmon;
    margin-bottom: 0.25rem;
    text-overflow: ellipsis;
    text-decoration: none;
    white-space: nowrap;
    overflow: hidden;
    margin-right: 20%;
    font-size: $font-text-xs;

    @include breakpoint(md) {
      font-size: $font-text-base;
    }
  }

  &__bottomline {
    display: block;
    font-size: $font-text-xs;
  }

  &__link {
    display: block;
    color: $color-purple;
    text-decoration-color: $color-purple;

    &:hover,
    &:active,
    &:focus {
      text-decoration: underline;
      color: $color-purple;
      text-decoration-color: $color-purple;
    }

    &:visited {
      color: $color-grape;

      &:hover,
      &:active,
      &:focus {
        text-decoration-color: $color-grape;
      }
    }
  }

  &__headline {
    display: block;
    font-weight: 500;
    margin-top: 0.5rem;
    margin-bottom: 10px;
    font-size: $font-text-base;
    hyphens: auto;
    color: $color-purple;

    @include breakpoint(md) {
      font-size: $font-text-mbase;
    }

    #{$teaser}--has-meta & {
      margin-right: 20%;
    }
  }

  &__text {
    margin-bottom: 0;
    color: $color-charcoal-dark;
    font-weight: 400;
    font-size: $font-text-xs;
    line-height: $font-text-sm;
    word-break: break-word;

    @include breakpoint(md) {
      font-size: $font-text-base;
      line-height: $font-text-s;
    }
  }

  &__subline {
    @include list-reset();

    display: flex;
    flex-wrap: wrap;
    font-size: $font-text-xs;
    font-weight: 400;
    color: $color-charcoal-dark;

    &--mobile {
      width: 100%;

      @include breakpoint(sm) {
        display: none;
      }
    }

    &--desktop {
      display: none;

      @include breakpoint(sm) {
        display: flex;
      }
    }

    li {
      display: flex;
      align-items: flex-start;
      margin-right: 1rem;
      line-height: $font-text-base;
    }

    .icon {
      margin-right: 0.25rem;
    }

    #{$teaser}__text + & {
      margin-top: 0.5rem;

      @include breakpoint(sm) {
        margin-top: 1.25rem;
      }
    }
  }

  &__meta {
    position: absolute;
    top: 15px;
    right: 10px;
    display: flex;
    align-items: center;

    @include breakpoint(md) {
      right: 15px;
    }
  }

  &__label {
    border-radius: $border-radius-sm;
    border: 1px solid $color-lightgrey-1;
    text-transform: uppercase;
    font-size: 0.625rem;
    line-height: 1;
    padding: 0 0.625rem;
    height: 1.4rem;
    color: $color-charcoal;
    display: flex;
    align-items: center;
    margin-right: 0.75rem;

    &:hover,
    &:active,
    &:focus {
      color: $color-charcoal;
      text-decoration: none;
    }
  }

  &__container {
    @media (hover: none) {
      position: relative;
      padding: 15px;
      z-index: 10;
      background-color: $color-white;
      border-radius: $border-radius-sm;
      transform: translateX(var(--swipe));
      transition: transform 0.2s ease-out;
    }
  }

  &__preferences {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    border-radius: 12px;
    overflow: hidden;
    border: 1px solid $color-snow;

    @media (hover: hover) {
      display: none;
    }

    &-upvote,
    &-downvote {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      padding: 1rem 2rem 1rem 1rem;
      flex: 0 0 calc(100px + 1rem);
      color: $color-white;
      background-color: $color-azure;
      grid-gap: 0.25rem;
      gap: 0.25rem;
      justify-content: center;

      svg path {
        stroke: $color-white;
      }

      span {
        display: block;
      }
    }

    &-downvote {
      padding: 1rem 1rem 1rem 2rem;
      background-color: $color-red;
    }
  }

  &__settings {
    visibility: hidden;
    pointer-events: none;
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;

    @media (hover: none) {
      z-index: 15;
      visibility: visible;

      &.visible {
        visibility: visible;
      }
    }

    @media (hover: hover) {
      pointer-events: inherit;

      &.visible,
      #{$teaser}:hover & {
        visibility: visible;
      }
    }

    &-upvote {
      svg path {
        stroke: $color-azure;
      }
    }

    &-downvote {
      svg path {
        stroke: $color-red;
      }
    }

    &-neutral {
      @media (hover: none) {
        display: none;
      }
    }

    &-toggle {
      background-color: transparent;
      border: 0;
      cursor: pointer;
      padding: 0.25rem;

      #{$teaser}__settings:hover & {
        background-color: $color-snow;
        border-radius: $border-radius-ssm;

        svg circle,
        svg path {
          stroke: $color-purple;
        }
      }

      svg {
        display: block;

        @include breakpoint(sm-max) {
          width: 20px;
          height: 20px;
        }
      }
    }

    &-dropdown {
      position: absolute;
      visibility: hidden;
      pointer-events: none;
      z-index: $z-index-topbar;
      top: 100%;
      right: 0;
    }

    &-dropdown:hover,
    &-toggle:hover + &-dropdown {
      visibility: visible;
      pointer-events: all;
    }
  }

  &--horizontal {
    $mobile-image-size: 72;
    $desktop-image-size: 140;

    padding: 15px;
    overflow: hidden;

    @include breakpoint(sm) {
      display: flex;
    }

    #{$teaser}__text {
      @include breakpoint(sm-max) {
        display: none;
      }
    }

    #{$teaser}__content {
      padding: 0;
      width: calc(100% - 87px); // 72px + 15px(padding)
      min-height: #{$mobile-image-size}px;

      @include breakpoint(sm) {
        width: calc(100% - 155px); // 140px + 15px(padding)
      }
    }

    #{$teaser}__image-wrapper {
      margin-left: 0.5rem;
      margin-bottom: 0.5rem;
      width: #{$mobile-image-size}px;
      float: right;

      @include breakpoint(sm) {
        margin-left: 0;
        margin-bottom: 0;
        margin-right: 1rem;
        flex: 1 0 #{$desktop-image-size}px;
        float: none;
      }
    }

    #{$teaser}__image {
      position: relative;
      width: 100%;
      height: auto;
      top: auto;
      left: auto;
      min-width: 0;
      min-height: 0;
      max-width: none;
      max-height: none;
      transform: none;
      border-radius: $border-radius-s;
    }
  }

  &--reverse-horizontal {
    @include breakpoint(sm) {
      display: flex;
      flex-direction: row-reverse;
    }

    #{$teaser}__image-wrapper {
      @include breakpoint(sm) {
        margin-right: 0;
        margin-left: 1rem;
      }
    }
  }
}
