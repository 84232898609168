.german-box {
  &__flag {
    height: 6px;
    width: 100%;
    display: flex;
    flex-wrap: nowrap;

    @include breakpoint(md) {
      height: 12px;
    }
  }

  &__color {
    width: 100%;
    height: 100%;

    &--black {
      background-color: #000000;
    }

    &--red {
      background-color: #ff0000;
    }

    &--gold {
      background-color: #ffd600;
    }
  }

  &__container {
    width: 100%;
    background-color: #c4c4c4;
    height: 580px;
  }
}
