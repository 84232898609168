.carousel {
  position: relative;

  .swiper-container {
    @include breakpoint(md-max) {
      margin-left: -1rem;
      margin-right: -1rem;
      padding-left: 1rem;
      padding-right: 1rem;
    }

    @include breakpoint(sm-max) {
      margin-left: -0.5rem;
      margin-right: -0.5rem;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }
  }

  .swiper-wrapper {
    align-items: stretch;
    -webkit-transform-style: preserve-3d;
  }

  .swiper-slide {
    height: auto;
    -webkit-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
  }

  &__swiper {
    &--news {
      .swiper-slide {
        @include breakpoint(md-max) {
          width: 250px;
        }
      }
    }

    &--images {
      .swiper-slide {
        width: auto;
      }
    }
  }

  &__button {
    display: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: $z-index-carousel-buttons;

    @include breakpoint(md) {
      display: flex;
    }

    &--prev {
      left: -$search-bar-height-mobile * 0.75;

      @include breakpoint(lg) {
        left: calc(-#{$search-bar-height-mobile} - 1rem);
      }
    }

    &--next {
      right: -$search-bar-height-mobile * 0.75;

      @include breakpoint(lg) {
        right: calc(-#{$search-bar-height-mobile} - 1rem);
      }
    }

    &.swiper-button-disabled {
      display: none;
    }
  }
}
