.figure {
  &--decoration-bottom {
    margin-bottom: -3rem;
  }

  &--white-gradient {
    position: relative;

    &::after {
      content: "";
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 75%;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 10.94%, $color-white 68.23%);
    }
  }
}
