.widget-tellows {
  &__desc {
    border: 1px solid $color-lightgrey-1;
    border-radius: $border-radius-s;
    padding: 1rem;
    margin-bottom: 1rem;
    line-height: 1.2;

    & > * {
      margin-bottom: 0;
    }

    &-headline {
      padding-bottom: 0.5rem;
      font-weight: 500;
      font-size: $font-text-mbase;
      margin-bottom: 0.5rem;
    }
  }

  &__details {
    flex-direction: column;
    grid-gap: 1rem;
    font-weight: 500;
    margin-bottom: 1rem;
    display: grid;
    grid-template-columns: repeat(2, auto);

    @include breakpoint(md) {
      grid-template-columns: repeat(4, auto);
    }

    &-headline {
      display: block;
      font-weight: 400;
      margin-bottom: 0.5rem;
    }
  }

  &__score {
    display: flex;
    grid-gap: 0.25rem;

    span {
      border-radius: 4px;
      display: inline-block;
      padding: 0.25rem;
      color: $color-white;
      height: 1.5rem;

      &.active {
        padding: 0.25rem 0.5rem;
      }

      &:nth-child(1) {
        background-color: #31c449;
      }

      &:nth-child(2) {
        background-color: #79c425;
      }

      &:nth-child(3) {
        background-color: #aac100;
      }

      &:nth-child(4) {
        background-color: #d6bb00;
      }

      &:nth-child(5) {
        background-color: #ffb200;
      }

      &:nth-child(6) {
        background-color: #ff9903;
      }

      &:nth-child(7) {
        background-color: #ff7f15;
      }

      &:nth-child(8) {
        background-color: #ff6225;
      }

      &:nth-child(9) {
        background-color: #ff4133;
      }
    }
  }

  &__type {
    &.score-1 {
      color: #31c449;
    }

    &.score-2 {
      color: #79c425;
    }

    &.score-3 {
      color: #aac100;
    }

    &.score-4 {
      color: #d6bb00;
    }

    &.score-5 {
      color: #ffb200;
    }

    &.score-6 {
      color: #ff9903;
    }

    &.score-7 {
      color: #ff7f15;
    }

    &.score-8 {
      color: #ff6225;
    }

    &.score-9 {
      color: #ff4133;
    }
  }
}
