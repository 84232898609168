.mobile-hide {
  display: flex !important;
}

/* Smartphone Portrait and Landscape */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .mobile-hide {
    display: none !important;
  }
}
