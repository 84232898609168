$imageTeaser: ".image-result-teaser";

.image-result-teaser {
  padding: 0.5rem;
  flex-basis: 0;
  flex-shrink: 1;
  flex-grow: var(--image-result-teaser-aspect-ratio);
  overflow: hidden;

  @include breakpoint((min: breakpoints(md), max: breakpoints(xxl))) {
    &.open {
      padding-bottom: calc(0.5rem + 400px);
    }
  }

  &__handle {
    display: block;
    position: relative;
    overflow: hidden;

    &,
    &:hover,
    &:active,
    &:focus {
      color: $color-purple;
    }
  }

  &__wrapper {
    position: relative;
    overflow: hidden;
    border-radius: $border-radius-sm;
  }

  &__image {
    display: block;
    max-width: none;
    height: auto;
    width: 100%;
  }

  &__content {
    width: 100%;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  &__subtitle,
  &__url {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__subtitle {
    color: $color-purple;
  }

  &__url {
    color: $color-middlegray-3;
    font-size: $font-text-xs;
  }

  &__detail-wrapper {
    display: none;
    z-index: $z-index-topbar;

    @include breakpoint(md-max) {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    @include breakpoint(xxl) {
      position: fixed;
      align-items: center;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.25);
      display: flex;
      flex-direction: column-reverse;
      justify-content: flex-end;
    }

    #{$imageTeaser}.open & {
      display: flex;
    }
  }

  &__detail {
    @include breakpoint(md-max) {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: $color-white;
      color: $color-charcoal;
    }

    @include breakpoint((min: breakpoints(md), max: breakpoints(xxl))) {
      position: absolute;
      top: auto;
      left: 0;
      width: 100%;
      height: 400px;
      padding: 1rem 4rem;
      background-color: $color-charcoal;
      color: $color-white;

      > * {
        flex: 0 1 50%;
        padding: 1rem;
      }
    }

    @include breakpoint(xxl) {
      position: relative;
      width: 75%;
      height: 75%;
      min-width: 800px;
      min-height: 500px;
      max-width: 1200px;
      max-height: 900px;
      background-color: $color-white;
      border-radius: $border-radius-l;
      padding: 2rem;
    }

    #{$imageTeaser}__url {
      color: $color-salmon;
    }
  }

  &__detail-image {
    max-width: 100%;
    max-height: 100%;
    height: auto;
    width: auto;
    margin: 0 auto;
  }

  &--minimal {
    #{$imageTeaser}__image {
      height: 200px;
      width: auto;
      max-width: none;
    }
  }
}
