// Dieses Mixin generiert Media Queries. Dazu kann dem Mixin ein String oder eine Map übergeben werden.
// Wird nur der Breakpoint übergeben wird eine min-width Query generiert.
//
// -> min-width: sm:
// .example {
//   @include breakpoint(sm) {
//     ...
//   }
// }
//
// Wird dem Breakpoint "-max" angehängt wird eine max-width Query generiert.
//
// -> max-width: lg - 1:
// .example {
//   @include breakpoint(lg-max) {
//     ...
//   }
// }
//
// Außerdem können spezifische Werte als Map übergeben werden, egal ob nur min, max oder beides zusammen.
//
// -> min-width: 100px and max-width: 1000px:
// .example {
//   @include breakpoint((min: 100px, max: 1000px)) {
//     ...
//   }
// }

@function breakpoints($breakpoint) {
  @if (map-has-key($breakpoints, $breakpoint)) {
    @return map-get($breakpoints, $breakpoint);
  } @else {
    @error "Breakpoint \"#{$breakpoint}\" is undefined.";
  }
}

@mixin breakpoint($query-input) {
  $query: "";
  $map: ();

  @if (type-of($query-input) == "map") {
    $map: $query-input;
  } @else if (type-of($query-input) == "string") {
    @if (str-index($query-input, "-max")) {
      $breakpoint: str-slice($query-input, 0, str-index($query-input, "-max") - 1);
      $map: (max: breakpoints($breakpoint) - 1);
    } @else {
      $map: (min: breakpoints($query-input));
    }
  }

  @if map-has-key($map, min) {
    $query: append($query, "(min-width: #{map-get($map, min)})");
  }

  @if map-has-key($map, min) and map-has-key($map, max) {
    $query: append($query, "and");
  }

  @if map-has-key($map, max) {
    $query: append($query, "(max-width: #{map-get($map, max)})");
  }

  @media screen and #{$query} {
    @content;
  }
}
