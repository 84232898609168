.inactive-wrapper {
  position: relative;

  & > * {
    opacity: 0.4;

    * {
      pointer-events: none !important;
    }
  }

  &__message {
    opacity: 1;
    position: absolute;
    top: 5%;
    left: 50%;
    max-width: 80%;
    width: 100%;
    background-color: $color-white;
    padding: 1.5rem;
    border-radius: $border-radius-m;
    transform: translate(-50%, 0);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1), 0 1px 1px rgba(0, 0, 0, 0.06);
    text-align: center;
  }
}
