.video-results {
  display: flex;
  flex-wrap: wrap;

  &__item {
    margin-bottom: 1rem;
    padding: 0.5rem;
    flex: 0 1 100%;
    width: 100%;

    @include breakpoint(sm) {
      flex: 0 1 50%;
      width: 50%;
    }

    @include breakpoint(md) {
      flex: 0 1 calc(100% / 3);
      width: calc(100% / 3);
    }

    @include breakpoint(xl) {
      flex: 0 1 25%;
      width: 25%;
    }

    @include breakpoint(xxl) {
      flex: 0 1 calc(100% / 6);
      width: calc(100% / 6);
    }
  }
}
