.navbar {
  padding: 1rem 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: $z-index-toolbar;
  transform: translateY(0%);
  transition: padding 0.2s ease-in-out, box-shadow 0.2s ease-in-out, transform 0.2s ease-in-out;

  & + .spacer {
    height: calc(#{$search-bar-height-mobile} + 2rem);

    @include breakpoint(md) {
      height: calc(#{$search-bar-height-desktop} + 2rem);
    }
  }

  &.hide {
    transform: translateY(-100%);
  }

  &__wrapper {
    display: flex;
  }

  &__logo {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    margin-right: 1rem;
  }

  &__search {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
  }
}
