.search-cta {
  @include var(background-color, bg-search-results);

  height: 100px;
  min-height: 80vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-items: center;
  justify-content: center;

  &__wrapper {
    margin: 0 20px;
    text-align: center;
    z-index: 3;
  }

  &__headline {
    @include var(color, text-02-color);

    font-size: $font-text-mbase;
    font-weight: 400;
    line-height: 130%;
    margin-bottom: 5px;

    @include breakpoint(md) {
      font-size: $font-text-s;
      margin-bottom: 0;
    }
  }

  &__subline {
    @include var(color, text-03-color);

    font-size: $font-text-mbase;
    font-weight: 400;
    line-height: 130%;
    margin-bottom: 35px;

    @include breakpoint(md) {
      font-size: $font-text-s;
    }
  }

  &__caption {
    @include var(color, text-05-color);

    font-size: $font-text-xs;

    @include breakpoint(md) {
      font-size: $font-text-base;
    }
  }

  &__caption-link {
    @include var(color, text-05-color);

    &:hover {
      @include var(color, text-01-color);
    }
  }

  &__cta {
    margin-bottom: 20px;
  }
}
