.lp-content {
  line-height: 1.5;

  &--center {
    text-align: center;
  }

  &--spacing {
    margin-top: 2rem;
    margin-bottom: 2rem;

    @include breakpoint(lg) {
      margin-top: 5rem;
      margin-bottom: 5rem;
    }
  }

  &--flex {
    display: flex;
    flex-wrap: wrap;
  }

  &__text {
    br {
      @include breakpoint(sm-max) {
        display: none;
      }
    }
  }
}

.lp-grid {
  margin: -0.5rem;
  display: flex;
  flex-wrap: wrap;

  &--align-items-center {
    align-items: center;
  }
}

.lp-col {
  padding: 0.5rem;
  width: 100%;
  flex: 0 0 100%;

  &--6 {
    @include breakpoint(sm) {
      width: 50%;
      flex: 0 0 50%;
    }
  }

  &--4 {
    @include breakpoint(sm) {
      width: calc(100% / 3);
      flex: 0 0 calc(100% / 3);
    }
  }

  &--60 {
    @include breakpoint(sm) {
      padding: 1.5rem;
      width: 65%;
      flex: 0 0 60%;
    }
  }

  &--40 {
    @include breakpoint(sm) {
      padding: 1.5rem;
      width: 35%;
      flex: 0 0 40%;
    }
  }
}
