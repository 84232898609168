.settings {
  $self: &;

  &__row {
    margin: 2rem 0;
  }

  &__group {
    display: flex;
    grid-gap: 1rem;
    align-items: flex-start;

    @include breakpoint(sm-max) {
      flex-wrap: wrap;
    }

    &--has-link {
      align-items: flex-end;
    }

    & + #{$self}__group {
      margin-top: 2rem;
    }

    & + #{$self}__group:not(.hide-border) {
      border-top: 1px solid $color-lightgrey-3;
      padding-top: 2rem;
    }
  }

  &__content {
    flex: 1 1 auto;
  }

  &__headline {
    color: $color-purple;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 0.25rem;
  }

  &__action {
    flex: 0 0 auto;
  }

  &__desc {
    font-size: 14px;
    color: $color-middlegray-3;

    a {
      color: $color-middlegray-3;
      text-decoration: underline;

      &:hover {
        color: $color-azure;
      }
    }
  }
}
