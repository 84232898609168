a {
  @include focus-outline-style();
  @include list-reset;

  text-decoration: none;
  display: inline-block;
  color: $color-azure;

  &:active,
  &:hover,
  &:focus {
    text-decoration: underline;
  }
}
