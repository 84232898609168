.page-wrapper {
  width: 100%;
  margin-right: auto;
  margin-left: auto;

  &--fixed-height {
    overflow: hidden;
    height: 100vh;
  }

  &--search-results-page {
    @include var(background-color, bg-search-results);

    height: 100%;
  }
}

.page {
  &--front {
    @include var(background-color, bg-search-results);
  }

  &--results {
    @include var(background-color, bg-search-results);
  }

  &--content {
    @include var(background-color, bg-search-results);
  }

  &--fixed {
    overflow: hidden;
    height: 100vh;
  }

  &__container {
    position: relative;
    min-height: 100vh;
    min-height: calc(var(--vh, 1vh) * 100);
    padding-bottom: $footer-height;
  }
}
