.hero {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include breakpoint(md-max) {
    min-height: 100vh;
    min-height: calc(var(--vh, 1vh) * 100);
    justify-content: center;
  }

  @include breakpoint(md) {
    margin: 2rem 0;
  }

  &__section {
    padding: 0 1rem;

    @include breakpoint(md) {
      padding: 0 2rem;
    }
  }

  &__logo {
    display: block;
    border: 2px solid transparent;
    margin: 1rem 0;

    @include breakpoint(md) {
      margin: 2rem 0;
    }

    svg {
      display: block;
    }
  }
}

.chevron::before {
  border-style: solid;
  border-width: 0.25rem 0.25rem 0 0;
  content: "";
  display: inline-block;
  height: 0.9rem;
  left: 0.3rem;
  position: relative;
  top: 0.3rem;
  transform: rotate(-45deg);
  vertical-align: top;
  width: 0.9rem;
  margin: 1rem;
}

.chevron.chevron--down::before {
  top: 0;
  transform: rotate(135deg);
}
