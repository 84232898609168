@mixin button-icon-color($button, $color, $background) {
  color: $color;
  background-color: $background;

  &#{$button}--icon-only,
  &#{$button}--with-icon-before,
  &#{$button}--with-icon-after {
    svg {
      path {
        fill: $color;
      }
    }
  }
}

.button {
  $button: &;

  @include focus-outline-style();

  border: 0;
  border-radius: 0.5rem;
  min-height: 48px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 15px 20px;
  cursor: pointer;
  font-weight: 500;

  &:hover {
    text-decoration: none;
  }

  &--small {
    min-height: 24px;
    border-radius: 0.25rem;
    padding: 0 15px;
    font-size: 13px;
  }

  &--nowrap {
    white-space: nowrap;
  }

  &--icon-only {
    padding: 10px;
  }

  &--with-icon-before {
    padding: 10px 20px;

    svg {
      margin-right: 0.5rem;
    }
  }

  &--with-icon-after {
    padding: 10px 20px;

    svg {
      margin-left: 0.5rem;
    }
  }

  &--primary {
    @include button-icon-color($button, $color-charcoal, $color-snow);

    &:hover {
      @include button-icon-color($button, $color-charcoal-dark, $color-lightgrey-1);
    }
  }

  &--blue {
    @include button-icon-color($button, $color-white, $color-azure);

    &:hover {
      @include button-icon-color($button, $color-white, $color-purple);
    }
  }

  &--secondary {
    @include button-icon-color($button, $color-middlegray-3, transparent);

    border: 1px solid $color-lightgrey-1;

    &:hover {
      @include button-icon-color($button, $color-charcoal-dark, transparent);

      border: 1px solid $color-charcoal-dark;
    }
  }

  &--tertiary {
    @include button-icon-color($button, $color-purple, $color-white);

    &:hover {
      background-color: $color-lightgrey-1;
    }
  }

  &--full-width {
    width: 100%;
  }

  &.hide {
    display: none;
  }
}
