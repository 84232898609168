.section {
  margin: 1rem auto;

  @include breakpoint(sm) {
    margin: 2rem auto;
  }

  &--big {
    margin: 2rem auto;

    @include breakpoint(sm) {
      margin: 4rem auto;
    }
  }
}
