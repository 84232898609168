.image-with-circles {
  overflow: hidden;
  max-width: 100vw;
  height: 100%;

  &__container {
    margin: 85px 20px 100px;

    @include breakpoint(sm) {
      margin: 120px 60px 160px;
    }
  }

  &__image-wrapper {
    display: block;
    max-width: 847px;
    width: 100%;
    position: relative;
    margin: 0 auto;

    &::before,
    &::after {
      display: block;
      content: "";
      position: absolute;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      height: 70%;
      width: 50%;
      z-index: -1;
    }

    &::before {
      background-image: url("../../images/kringel-pink.svg");
      top: -68px;
      left: -80px;

      @include breakpoint(sm) {
        top: -95px;
        left: -110px;
      }
    }

    &::after {
      background-image: url("../../images/kringel-blue.svg");
      bottom: -60px;
      right: -70px;

      @include breakpoint(sm) {
        bottom: -78px;
        right: -110px;
      }
    }
  }

  &__image {
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  }
}
