.feature-section {
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  max-width: $container-section;
  margin: 0 auto 50px;

  @include breakpoint(sm) {
    flex-direction: row;
    justify-content: space-between;
    margin: 0 auto 70px;
  }

  @include breakpoint(lg) {
    margin: 0 auto 90px;
  }

  &__feature-wrapper {
    width: 100%;
    order: 2;

    @include breakpoint(sm) {
      width: 40%;
      order: 1;
      padding-top: 40px;
    }
  }

  &__feature {
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    @include breakpoint(sm) {
      margin-bottom: 30px;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__feature-text {
    font-family: $font;
    font-size: $font-text-base;
    font-weight: normal;

    @include breakpoint(lg) {
      font-size: $font-text-s;
    }
  }

  &__image {
    width: 100%;
    order: 1;
    margin-bottom: 30px;
    z-index: 1;
    position: relative;

    @include breakpoint(sm) {
      width: 60%;
      order: 2;
      margin-bottom: 0;
    }
  }
}
