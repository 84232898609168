.polelinks {
  margin: 1rem 0;

  &__item {
    width: auto;

    &:last-child {
      margin-right: 0;
    }
  }

  &__button {
    box-shadow: 0 0 5px 2px $color-snow;
  }

  &__link {
    background-color: $color-white;
    border-radius: $border-radius-ssm;
    padding: 1rem;
    display: block;
    white-space: nowrap;
    color: $color-charcoal-dark;
    font-weight: 500;

    &:hover,
    &:active,
    &:focus {
      background-color: $color-lightgrey-1;
      text-decoration: none;
    }
  }
}
