html.landingpage {
  font-size: 16px;

  @include breakpoint(lg) {
    font-size: 22px;
  }
}

.lp-article-flow {
  & > * {
    margin-bottom: 0;
  }

  & > * + * {
    margin-top: 1rem;
  }
}

.lp-content-flow {
  & > * {
    margin-bottom: 0;
  }

  & > * + * {
    margin-top: 2rem;
  }
}
