$teaser: ".entity-teaser";

.entity-teaser {
  &__wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: visible;
    padding: 15px;
    background-color: $color-white;
    border-radius: $border-radius-sm;

    @include breakpoint(sm) {
      flex-direction: row;
    }
  }

  &__content {
    padding: 0;

    @include breakpoint(sm) {
      width: calc(100% - 141px); // 125px + 1rem
    }
  }

  &__image-wrapper {
    position: relative;
    overflow: hidden;
    margin-bottom: 1rem;

    @include breakpoint(sm) {
      margin-bottom: 0;
      margin-right: 1rem;
      flex: 1 0 140px;
    }
  }

  &__image {
    position: relative;
    width: 140px;
    height: auto;
    border-radius: $border-radius-s;
  }

  &__topline {
    display: block;
    font-size: 1rem;
    line-height: 1.2;
    color: $color-salmon;
    margin-bottom: 5px;
    text-overflow: ellipsis;
    text-decoration: none;
    white-space: nowrap;
    overflow: hidden;
    margin-right: 20%;
  }

  &__headline {
    display: block;
    font-weight: 500;
    margin-bottom: 0.5rem;
    font-size: $font-text-mbase;
    hyphens: auto;

    @include breakpoint(md) {
      font-size: $font-text-sm;
    }

    #{$teaser}--has-meta & {
      margin-right: 20%;
    }
  }

  &__category {
    font-size: $font-text-mbase;
    font-weight: 400;
    color: $color-purple;
    margin-bottom: 1.5rem;
  }

  &__link {
    display: block;
    color: $color-purple;
    text-decoration-color: $color-purple;

    &:hover,
    &:active,
    &:focus {
      text-decoration: underline;
      color: $color-purple;
      text-decoration-color: $color-purple;
    }

    &:visited {
      color: $color-grape;

      &:hover,
      &:active,
      &:focus {
        text-decoration-color: $color-grape;
      }
    }
  }

  &__text {
    margin-bottom: 1rem;
    color: $color-charcoal-dark;
    font-weight: 400;
    font-size: $font-text-xs;
    line-height: $font-text-sm;
    word-break: break-word;

    @include breakpoint(md) {
      font-size: $font-text-base;
      line-height: $font-text-s;
    }
  }

  &__subline {
    @include list-reset();

    display: flex;
    flex-wrap: wrap;
    color: $color-middlegray;
    font-size: $font-text-xxs;
    font-weight: 400;
    margin-top: 0.5rem;

    @include breakpoint(md) {
      font-size: $font-text-xs;
    }

    li {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-right: 0.5rem;

      &:not(:first-child) {
        &::before {
          content: "•";
          display: inline-block;
          margin: 0 0.5rem 0 0;
        }
      }
    }

    a,
    a:visited {
      color: $color-middlegray;
    }

    a:focus,
    a:active,
    a:hover {
      color: $color-middlegray;
      text-decoration: underline;
    }

    #{$teaser}__text + & {
      margin-top: 10px;
    }
  }
}
