.lottery-table {
  display: grid;
  margin-bottom: 1rem;

  @include breakpoint(md) {
    grid-template-columns: auto auto auto;
    grid-template-rows: auto auto;
    grid-template-areas:
      "title-1 title-2 title-3"
      "numbers-1 numbers-2 numbers-3";
    grid-column-gap: 1rem;
    grid-row-gap: 0.25rem;
  }

  &__column {
    color: $color-middlegray-3;
    display: flex;
    line-height: 1.4;

    @include breakpoint(md-max) {
      flex-wrap: wrap;
      margin-bottom: 0.5rem;
      align-items: flex-end;

      &:nth-child(2n) {
        margin-bottom: 1rem;
      }
    }

    @include breakpoint(md) {
      &.title-1 {
        grid-area: title-1;
      }

      &.title-2 {
        grid-area: title-2;
      }

      &.title-3 {
        grid-area: title-3;
      }

      &.numbers-1 {
        grid-area: numbers-1;
      }

      &.numbers-2 {
        grid-area: numbers-2;
      }

      &.numbers-3 {
        grid-area: numbers-3;
      }
    }
  }

  &__number {
    display: inline-flex;
    border-radius: 2rem;
    border: 1px solid $color-lightgrey-1;
    color: $color-charcoal;
    font-size: $font-text-mbase;
    font-weight: 500;
    line-height: 1;
    height: 2.5rem;
    padding: 0 0.5rem;
    min-width: 2.5rem;
    align-items: center;
    justify-content: center;

    @include breakpoint(md-max) {
      margin-right: 0.25rem;
      margin-bottom: 0.25rem;
    }

    @include breakpoint(md) {
      &:not(&:last-child) {
        margin-right: 0.375rem;
      }
    }

    &--special {
      border-color: $color-salmon;
      background-color: $color-salmon;
      color: $color-white;
    }
  }
}
