.cms-cards {
  display: grid;
  grid-template-columns: 220px;
  margin: 0 auto;
  gap: 25px;

  @include breakpoint(sm) {
    grid-template-columns: 220px 220px;
  }

  @include breakpoint(lg) {
    grid-template-columns: 220px 220px 220px;
    max-width: 710px;
  }

  &__card {
    width: 100%;
    height: 100px;
    background: #ffffff;
    border-radius: 15px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-self: center;

    @include breakpoint(lg) {
      &:first-of-type {
        position: relative;
        grid-column: 2 / 3;
        grid-row: 1;

        &::before {
          content: "";
          position: absolute;
          background-image: url("../../images/red-arrow.svg");
          background-repeat: no-repeat;
          background-size: contain;
          width: 78px;
          height: 85px;
          left: -104px;
          top: -16%;
        }
      }

      &:nth-of-type(2) {
        grid-column: 1 / 2;
        grid-row: 2;
      }

      &:nth-of-type(3) {
        grid-column: 2 / 3;
        grid-row: 2;
      }

      &:nth-of-type(4) {
        grid-column: 3 / 4;
        grid-row: 2;
      }

      &:nth-of-type(5) {
        grid-column: 1 / 2;
        grid-row: 3;
      }

      &:nth-of-type(6) {
        grid-column: 2 / 3;
        grid-row: 3;
      }

      &:nth-of-type(7) {
        grid-column: 3 / 4;
        grid-row: 3;
      }

      &:nth-of-type(8) {
        grid-column: 2 / 3;
        grid-row: 4;
      }
    }
  }

  &__box {
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: #ffb26b;
    color: #ffffff;
    border-radius: 20px;
    padding: 5px 10px;

    &--green {
      background-color: #26b64e;
    }

    &-text {
      text-transform: uppercase;
      font-weight: bold;
      font-size: $font-text-xxs;
    }
  }

  &__logo-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__logo {
    height: 40px;

    &--api {
      height: 18px;
    }

    &--wordpress {
      height: 38px;
    }

    &--kirby {
      height: 46px;
    }

    &--ghost {
      height: 44px;
    }

    &--joomla {
      height: 28px;
    }
  }
}
