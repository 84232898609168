.news-teaser {
  @include var(background-color, bg-elements-01);

  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  border-radius: $border-radius-m;

  &__image-wrapper {
    position: relative;
    height: 124px;
    overflow: hidden;
    flex: 0 0 124px;
    background-color: $color-middlegray;
  }

  &__image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    object-fit: cover;
  }

  &__time-wrapper {
    @include var(background-color, bg-elements-08);

    position: absolute;
    bottom: 10px;
    right: 10px;
    padding: 4px 5px;
    border-radius: $border-radius-s;
  }

  &__time {
    @include var(color, text-06-color);

    font-size: $font-text-xs;
  }

  &__content {
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }

  &__link {
    border-bottom: none;
    width: 100%;
    color: $color-purple;

    &:hover,
    &:active,
    &:focus {
      text-decoration: underline;
      text-decoration-color: $color-purple;
    }
  }

  &__headline {
    @include var(color, text-02-color);

    font-size: $font-text-mbase;
    font-weight: 500;
    margin-bottom: 10px;
    hyphens: auto;

    @include breakpoint(md) {
      font-size: $font-text-mbase;
      line-height: $font-text-m;
    }
  }

  &__source {
    @include var(color, text-03-color);

    font-weight: 400;
    line-height: $font-text-mbase;
    margin-bottom: 2px;
  }

  &__published {
    color: $color-charcoal-dark;
    font-size: $font-text-xs;
    font-weight: 400;
    line-height: $font-text-base;
    margin: 0;
  }

  &__details {
    align-self: end;
    width: 100%;
  }
}
