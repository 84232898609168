.lp-container {
  max-width: $lp-container;
  margin: 0 auto;
  padding: 0 1rem;

  &--narrow {
    max-width: $lp-container-narrow;
  }

  &--wide {
    max-width: $lp-container-wide;
  }

  &--extended {
    max-width: $lp-container-extended;
  }
}
