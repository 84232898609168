.image-slider {
  display: flex;
  flex-wrap: nowrap;
  position: relative;
  list-style-type: none;
  height: 130px;
  overflow-y: hidden;

  &__item {
    position: relative;
    height: 100%;
    margin-right: 10px;

    &:last-of-type {
      margin-right: 0;
    }
  }

  &__image-wrapper {
    height: 100%;
  }

  &__image {
    height: 100%;
    width: unset;
    max-width: unset;
  }

  &__link {
    border-bottom: none;
    height: 100%;

    &::before {
      z-index: 1;
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
