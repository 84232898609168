.extensions {
  padding: 80px 20px;
  display: flex;
  flex-direction: column;

  @include breakpoint(sm) {
    padding: 135px 20px;
  }

  &__text-wrapper {
    width: 100%;
    margin: 0 auto;
    max-width: 566px;
    margin-bottom: 50px;

    @include breakpoint(md) {
      margin-bottom: 70px;
    }
  }

  &__wrapper {
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
    margin: 0 auto;
    max-width: 1126px;

    @include breakpoint(md) {
      padding-left: 30px;
      padding-right: 30px;
    }

    & > *:not(:first-child) {
      padding-top: 40px;

      @include breakpoint(md) {
        padding-top: 55px;
      }
    }

    & > *:not(:last-child) {
      padding-bottom: 40px;

      @include breakpoint(md) {
        padding-bottom: 55px;
      }

      &::after {
        content: "";
        position: absolute;
        height: 1px;
        width: 100px;
        background-color: #dddddd;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  &__content {
    max-width: 445px;
    margin-bottom: 30px;

    @include breakpoint(md) {
      width: 60%;
      max-width: 445px;
      margin-right: 40px;
      margin-bottom: 0;
    }

    &-headline {
      text-align: right;
      font-weight: 700;
      font-size: 18px;
      line-height: 139%;
      margin-bottom: 3px;

      @include breakpoint(md) {
        font-size: 25px;
      }
    }

    &-text {
      text-align: right;
      font-size: 14px;
      font-weight: 300;
      line-height: 139%;
      color: #444444;

      @include breakpoint(md) {
        font-size: 17px;
      }
    }
  }

  &__image-wrapper {
    max-width: 445px;
    width: 100%;

    @include breakpoint(sm) {
      min-width: 386px;
    }

    @include breakpoint(md) {
      width: 40%;
      flex-shrink: 0;
    }
  }

  &__feature {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    position: relative;

    @include breakpoint(md) {
      align-items: center;
      flex-direction: row;
      justify-content: flex-end;
    }

    &:nth-of-type(even) {
      justify-content: flex-start;

      @include breakpoint(md) {
        justify-content: flex-start;
      }

      .extensions__content {
        @include breakpoint(md) {
          order: 2;
          margin-right: 0;
          margin-left: 40px;
        }
      }

      .extensions__image-wrapper {
        @include breakpoint(md) {
          order: 1;
        }
      }

      .extensions__content-headline {
        text-align: left;
      }

      .extensions__content-text {
        text-align: left;
      }
    }
  }
}
