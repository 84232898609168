.pagination {
  display: flex;
  list-style-type: none;
  flex-wrap: nowrap;
  justify-content: center;
  margin: 2rem 0;
  padding: 0;

  @include breakpoint(md) {
    margin: 4rem 0;
  }

  &.hide {
    display: none;
  }

  &__item {
    position: relative;
    margin-right: 10px;
    padding: 0 10px;

    @include breakpoint(sm) {
      margin-right: 15px;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }

  &__arrow {
    display: flex;
    background-repeat: no-repeat;
    position: relative;
    width: 13px;
    height: 13px;
    margin-right: 3px;
    top: 1px;
    background-size: contain;
    background-image: url("../../images/icons/icon-arrow-side.svg");

    &--prev {
      transform: rotate(180deg);
      transform-origin: center;
    }

    &:hover {
      background-image: url("../../images/icons/icon-arrow-side-light.svg");
    }
  }

  &__page {
    @include var(color, text-02-color);

    border-bottom: none;
    font-weight: 500;
    font-size: $font-text-base;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    &:hover {
      @include var(color, text-05-color);
    }

    &.disabled,
    &.active {
      @include var(color, text-05-color);

      pointer-events: none;
    }

    &.disabled > .pagination__arrow {
      background-image: url("../../images/icons/icon-arrow-side-light.svg");
    }
  }
}
