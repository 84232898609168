/* stylelint-disable no-descending-specificity */
.search-bar-wrapper {
  position: relative;
  overflow: visible;
  height: $search-bar-height-mobile;
  width: 100%;

  @include breakpoint(md) {
    height: $search-bar-height-desktop;
  }

  &.standalone {
    height: 64px;
  }
}

.search-bar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: $search-bar-height-mobile;
  z-index: 100;
  display: flex;
  align-items: center;
  background-color: $color-white;
  border: 1px solid $color-lightgrey-1;
  border-radius: 28px;
  font-size: $font-text-base;
  box-shadow: $shadow-searchfield;

  @include breakpoint(md) {
    min-height: $search-bar-height-desktop;
  }

  &:focus,
  &:focus-visible,
  &:focus-within {
    box-shadow: 0 6px 12px rgba(51, 51, 51, 0.14), 0 0 16px rgba(51, 51, 51, 0.12), 0 4px 6px rgba(51, 51, 51, 0.16);
  }

  .standalone & {
    min-height: 64px;
    border-radius: $border-radius-xl;
    justify-content: flex-start;
    font-size: $font-text-mbase;
  }

  &__form {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;

    .standalone & {
      min-height: 64px;
    }
  }

  &__search-icon,
  &__clear-icon {
    display: block;
    position: absolute;
    top: 9px;
    right: 1rem;
    color: $color-middlegray;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    z-index: 110;
    cursor: pointer;

    @include breakpoint(md) {
      top: 13px;
    }

    .standalone & {
      top: 17px;
    }

    svg {
      width: 30px;
      height: 30px;
      fill: currentColor;
      pointer-events: none;
    }
  }

  &__clear-icon {
    right: 3.7rem;
    width: 16px;
    height: 16px;
    top: 16px;

    @include breakpoint(md) {
      top: 20px;
    }

    .standalone & {
      top: 25px;
    }

    svg {
      width: 16px;
      height: 16px;
    }

    &.hide {
      display: none;
    }
  }

  &__input {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    margin: 0;
    border: 0;
    background-color: transparent;
    padding: 15px 5rem 15px 1.5rem;
    color: $color-charcoal-dark;
    outline: none !important;

    @include breakpoint(md) {
      font-size: $font-text-sm;
      padding: 17px 2.5rem 17px 1.5rem;
    }

    .standalone & {
      padding: 21px 2.5rem 21px 1.5rem;
    }

    &::-ms-clear {
      display: none;
      width: 0;
      height: 0;
    }

    &::-ms-reveal {
      display: none;
      width: 0;
      height: 0;
    }

    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
      display: none;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      @include var(color, text-02-color);

      -webkit-box-shadow: 0 0 0 30px white inset !important;
      -webkit-text-fill-color: var(--text-02-color);
      transition: background-color 5000s ease-in-out 0s;
    }
  }
}
