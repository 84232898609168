$container-section: 1170px;
$container-text: 590px;
$container-search-features: 640px;
$container-search-footer: 260px;

// Breakpoints übernommen von Bootstrap 4, können bei Bedarf erweitert werden
// Werte verstehen sich als Minimum (inklusive)

$breakpoints: (
  xs: 400px,
  sm: 576px,
  md: 732px,
  lg: 992px,
  xl: 1200px,
  xxl: 2000px
);

$search-bar-height-mobile: 48px;
$search-bar-height-desktop: 56px;
$footer-height: 250px;

$search-results-container: 732px;

$container: 732px;
$lp-container: 800px;
$lp-container-narrow: 720px;
$lp-container-wide: 940px;
$lp-container-extended: 1140px;

$z-index-topbar: 500;
$z-index-toolbar: 500;
$z-index-carousel-buttons: 100;
