.video-result-teaser {
  $teaser: &;

  @include var(background-color, bg-elements-01);

  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  border-radius: $border-radius-m;

  &__image-wrapper {
    position: relative;
    overflow: hidden;
    flex: 0 0 auto;
    background-color: $color-middlegray;
    padding-bottom: calc(56.25% - 4px);
  }

  &__image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    object-fit: cover;
  }

  &__image-meta {
    position: absolute;
    bottom: 0.5rem;
    right: 0.5rem;
  }

  &__image-duration {
    border-radius: $border-radius-s;
    background-color: rgba(255, 255, 255, 0.9);
    color: $color-charcoal-dark;
    padding: 0.25rem;
    font-size: $font-text-xxs;
    display: block;
  }

  &__topline {
    display: block;
    line-height: 1.2;
    color: $color-salmon;
    text-decoration: none;
    font-size: $font-text-xs;
  }

  &__content {
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }

  &__link {
    width: 100%;

    &:hover,
    &:active,
    &:focus {
      text-decoration: none;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }

  &__headline {
    @include var(color, text-02-color);

    font-size: $font-text-mbase;
    font-weight: 500;
    margin-bottom: 10px;
    hyphens: auto;
    color: $color-purple;
    position: relative;
    line-height: 1.2rem;
    width: 100%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;

    /*! autoprefixer: ignore next */
    -webkit-box-orient: vertical;

    @include breakpoint(md) {
      font-size: $font-text-mbase;
      line-height: $font-text-m;
    }

    &:hover,
    &:active,
    &:focus {
      text-decoration: underline;
    }
  }

  &__source {
    @include var(color, text-03-color);

    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    font-weight: 500;
    font-size: $font-text-xs;
    line-height: 1.4;
  }

  &__published {
    color: $color-charcoal-dark;
    font-weight: 400;
    margin: 0;
    line-height: 1.4;
  }

  &__details {
    display: block;
    width: 100%;
    align-self: end;
    justify-content: space-between;
    margin-top: 0.5rem;
    font-size: $font-text-xs;
    line-height: $font-text-base;
  }
}
