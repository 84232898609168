body,
h1,
h2,
h3,
h4,
h5,
h6,
blockquote,
pre,
dl,
dd,
form,
fieldset,
legend,
figure,
table,
th,
td,
caption,
hr {
  margin: 0;
  padding: 0;
  font-family: $font;
}

p {
  margin-top: 0;
}

fieldset {
  border: 0;
}

* {
  text-underline-offset: 0.2rem;
}

/*
  Entfernt `:focus`-Style, wenn `:focus-visible` verfügbar ist
  (nicht irritieren lassen, die Regel wird nur ausgeführt, wenn der Browser `focus-visible` kennt)
 */
*:focus:not(:focus-visible) {
  outline: none !important;
}
