/* stylelint-disable no-descending-specificity */
.form-switch {
  opacity: 0.00001;

  & + label {
    display: block;
    position: relative;
    width: 100%;
    margin-left: calc(52px + 1rem);
    cursor: pointer;
    padding: 5px 0;
    height: 28px;

    &::before {
      @include var(background-color, input-element-01);

      content: "";
      display: block;
      position: absolute;
      left: calc(-52px - 1rem);
      top: 0;
      width: 52px;
      height: 28px;
      border-radius: 15px;
      transition: background-color 0.2s ease-out;
    }

    &::after {
      @include var(background-color, input-element-02);

      content: "";
      display: block;
      position: absolute;
      width: 24px;
      height: 24px;
      top: 2px;
      left: calc(-50px - 1rem);
      transition: width 0.2s ease-out, left 0.2s ease-out, right 0.2s ease-out;
      border-radius: 15px;
    }

    &:hover::after {
      width: 28px;
    }
  }

  &:checked + label {
    &::before {
      background-color: $color-azure;
    }

    &::after {
      left: calc(-26px - 1rem);
    }

    &:hover::after {
      left: calc(-30px - 1rem);
    }
  }

  &:focus + label {
    &::before {
      outline-offset: 1px;
      outline-style: dashed;
      outline-width: 1px;
      outline-color: $color-azure;
    }
  }

  &:disabled {
    &::before {
      @include var(background-color, input-element-03);
    }

    &::after {
      @include var(background-color, input-element-04);
    }
  }
}

.form-switch--standalone {
  & + label {
    margin-left: 52px;
    width: auto;

    &::before {
      left: -52px;
    }

    &::after {
      left: -50px;
    }
  }

  &:checked + label {
    &::after {
      left: -26px;
    }

    &:hover::after {
      left: -30px;
    }
  }
}

.form-switch--reverse {
  & + label {
    margin-left: 0;
    margin-right: calc(52px + 1rem);

    &::before {
      left: auto;
      right: calc(-52px - 1rem);
    }

    &::after {
      left: auto;
      right: calc(-26px - 1rem);
    }

    &:hover::after {
      right: calc(-30px - 1rem);
    }
  }

  &:checked + label {
    &::after {
      left: auto;
      right: calc(-50px - 1rem);
    }

    &:hover::after {
      left: auto;
      right: calc(-50px - 1rem);
    }
  }
}
