/**
  Da die komma-separierte Schreibweise dazu führen würde, dass Browser, die `focus-visible` nicht kennen,
  die Regel nicht interpretieren, schreibt dieses Mixin den übergebenen Codeblock für uns doppelt.

  GEHT NICHT:
  &:focus,
  &:focus-visible { ... }

  GEHT:
  &:focus { ... }
  &:focus-visible { ... }
 */
@mixin focus-style {
  &:focus {
    @content;
  }

  &:focus-visible {
    @content;
  }

  &:focus-within {
    @content;
  }
}

/*
  Implementiert standardmäßig eine Dashed Outline als Focus Style. Standard Outline Farbe ist currentColor,
  kann aber per Parameter verändert werden. Kann durch zusätzliche Focus-Stylings ergänzt werden.

  Bspw.:

  @include focus-outline-style;

  @include focus-outline-style($color-coral);

  @include focus-outline-style($color-coral) {
    background-color: $color-coral-mid;
  }
 */
@mixin focus-outline-style($outline-color: currentColor) {
  @include focus-style {
    outline-offset: 1px;
    outline-style: dashed;
    outline-width: 1px;
    outline-color: $color-azure;

    /* stylelint-disable-next-line order/order */
    @content;
  }
}
