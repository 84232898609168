.lp-icon-teaser {
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid $color-lightgrey-1;
  border-radius: $border-radius-sm;
  width: 100%;
  padding: 1rem;

  @include breakpoint(sm) {
    flex-direction: column;
  }

  &__icon {
    &,
    svg {
      display: block;
      width: 80px;
      height: 80px;

      @include breakpoint(sm) {
        width: 190px;
        height: 190px;
      }
    }
  }

  &__content {
    text-align: left;
    padding-left: 2rem;

    @include breakpoint(sm) {
      padding: 1rem;
      text-align: center;
    }
  }

  &__headline {
    font-size: 1.25rem;
    line-height: 1.4;
    color: $color-charcoal-dark;

    @include breakpoint(sm) {
      font-size: 1.875rem;
    }

    @include breakpoint(lg) {
      font-size: 1.4rem;
    }
  }

  &__link {
    color: $color-purple;

    &:hover,
    &:active,
    &:focus {
      color: $color-grape;
    }
  }
}
