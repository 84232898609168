.related-query {
  @include var(border-color, border-element-01);

  border-style: solid;
  border-width: 1px;
  box-sizing: border-box;
  position: relative;
  border-radius: $border-radius-l;
  background-color: transparent;
  list-style-type: none;
  padding: 15px 20px;
  margin-bottom: 10px;
  width: 100%;

  @include breakpoint(sm) {
    width: calc(50% - 10px);
  }

  @include breakpoint(md) {
    &:hover {
      @include var(background-color, bg-elements-02);
    }
  }

  &:hover {
    @include transition(all 0.2s ease);

    box-shadow: $shadow-input-search;
  }

  &:nth-child(odd) {
    @include breakpoint(sm) {
      margin-right: 10px;
    }
  }

  &:nth-child(even) {
    @include breakpoint(sm) {
      margin-left: 10px;
    }
  }

  &__link {
    @include var(color, text-02-color);

    border-bottom: none;
    font-weight: 500;

    &:hover {
      @include var(color, text-02-color);
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
