.container {
  max-width: $container;
  margin: 0 auto;
  padding: 0 0.5rem;

  @include breakpoint(md) {
    padding: 0 1rem;
  }
}

.container-fluid {
  padding: 0 0.5rem;

  @include breakpoint(md) {
    padding: 0 1rem;
  }
}
