.headline {
  position: relative;
  font-family: $font;

  &--center {
    text-align: center;
  }

  &--level-1 {
    font-size: $font-h1-mobile;
    line-height: 96.5%;
    text-align: center;
    font-weight: 700;
    margin-bottom: 1.5rem;
    letter-spacing: -0.04em;
    color: $color-purple;

    @include breakpoint(md) {
      margin-bottom: 2.5rem;
      font-size: $font-h1;
    }
  }

  &--level-2 {
    font-size: $font-text-s;
    font-weight: 700;
    margin-bottom: 1rem;
    color: $color-purple;

    @include breakpoint(md) {
      font-size: $font-h2;
      margin-bottom: 1.5rem;
    }
  }

  &--level-3 {
    font-size: $font-text-s;
    font-weight: 500;
    margin-bottom: 1rem;
    color: $color-purple;

    @include breakpoint(sm) {
      font-size: $font-h3;
    }
  }

  &--level-4 {
    font-size: $font-text-sm;
    font-weight: 500;
    margin-bottom: 1rem;
    color: $color-purple;

    @include breakpoint(sm) {
      font-size: $font-h4;
    }
  }

  &--huge {
    font-weight: bold;
    letter-spacing: -4%;
    text-align: center;
    margin: 1.5rem 0;
    color: $color-purple;

    @include breakpoint(sm) {
      font-size: $font-h1;
      margin: 2.5rem 0;
    }

    @include breakpoint(lg) {
      font-size: 65px;
    }
  }

  &__link {
    &:active,
    &:focus,
    &:hover {
      color: $color-middlegray-3;
      text-decoration: underline;
    }
  }

  &--widget-section {
    color: $color-middlegray-3;
    font-size: $font-text-mbase;
    font-weight: 500;
    width: 100%;
    max-width: $search-results-container;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;

    svg {
      margin-right: 0.5rem;

      path {
        fill: $color-middlegray-3;
      }
    }
  }

  &--section {
    font-size: 25px;
    line-height: 33px;
    letter-spacing: -4%;
    margin-bottom: 15px;

    @include breakpoint(sm) {
      font-size: 40px;
      line-height: 69px;
      margin-bottom: 5px;
    }
  }

  &--centered {
    text-align: center;
  }
}
