.result-section {
  margin: 1.5rem 0;

  @include breakpoint(md) {
    margin: 2.5rem 0;
  }

  &__content {
    margin-bottom: 0.6rem;

    .teaser {
      margin-bottom: 0.6rem;
    }
  }
}
