.hero-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem 0;
  padding: 0 2rem;

  &--stretch {
    height: 77vh;
  }

  &--centered {
    text-align: center;
  }

  &__logo {
    display: block;
    border: 2px solid transparent;
    margin: 1rem 0;

    @include breakpoint(sm-max) {
      width: 60px;
    }

    @include breakpoint(md) {
      margin: 2rem 0;
    }

    svg {
      display: block;
      margin: 0 auto;
      width: 100%;
      height: auto;
    }

    &:focus,
    &:focus-within {
      outline: none;
      border: 2px solid $color-purple;
      border-radius: 20px;
    }
  }
}
