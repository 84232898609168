.page-border {
  display: flex;
  align-items: center;
  font-size: $font-text-xs;
  color: $color-middlegray-3;
  text-transform: uppercase;
  margin-bottom: 2rem;
  margin-top: 3.4rem;
  white-space: nowrap;

  @include breakpoint(lg) {
    margin-top: 4.4rem;
  }

  span {
    width: 100%;
    height: 1px;
    margin-left: 0.75rem;
    background-color: $color-middlegray-3;
  }
}
