$color-white: #ffffff;
$color-snow: #f4f4f4;
$color-offwhite: #fafafa;
$color-lightgrey-1: #dddddd;

$color-lightgrey-2: #cccccc;
$color-lightgrey-3: #e5e5e5;
$color-lightgrey-4: #d4d4d4;
$color-middlegray-2: #b3b3b3;
$color-middlegray: #999999;
$color-middlegray-3: #777777;
$color-middlegray-4: #737373;
$color-charcoal: #3e3e3e;
$color-charcoal-dark: #333333;
$color-black: #000000;

// Primary
$color-salmon: #ff968f;
$color-purple: #1b1464;
$color-sky: #00aff5;
$color-palesky: #8fdcfb;
$color-grape: #6f1b96;
$color-dimblue: #e8eeff;
$color-azure: #064cdb;
$color-violet: #3e30cd;
$color-pastelgrape: #704bbd;

// States
$color-green: #31c449;
$color-red: #ff4133;
$color-yellow: #ffb200;
$color-blue: #5cadff;

// Dark Mode
$color-darkblue: #03020e;
$color-darkgrey: #27272a;

$cssVariablesScreen: (
  "bg-search-results": #f4f4f4,
  "bg-suggestion-link": #f8f8f8,
  "bg-suggestion-link-hover": #f0f0f0,
  "color-link-hover": #f0f0f0,
  "bg-elements-01": $color-white,
  "bg-elements-02": #f1f1f1,
  "bg-elements-03": $color-white,
  "bg-elements-04": $color-salmon,
  "bg-elements-05": $color-black,
  "bg-elements-06": $color-purple,
  "bg-elements-07": #b0e3ff,
  "bg-elements-08": rgba(0, 0, 0, 0.5),
  "text-01-color": #333333,
  "text-02-color": $color-purple,
  "text-03-color": $color-salmon,
  "text-04-color": #d8d5f5,
  "text-05-color": $color-middlegray,
  "text-06-color": $color-white,
  "text-07-color": #6cd98b,
  "border-element-01": $color-lightgrey-1,
  "border-element-02": $color-white,
  "icon-element-01": $color-purple,
  "input-element-01": $color-lightgrey-2,
  "input-element-02": $color-white,
  "input-element-03": $color-lightgrey-2,
  "input-element-04": $color-middlegray,
);

$cssVariablesDarkMode: (
  "text-01-color": $color-lightgrey-2,
  "text-02-color": $color-white,
  "text-05-color": $color-middlegray-2,
  "bg-search-results": $color-black,
  "bg-elements-01": #222222,
  "bg-elements-02": $color-white,
  "bg-elements-03": #5a5a5f,
  "border-element-01": $color-charcoal,
  "icon-element-01": $color-salmon,
  "input-element-01": $color-charcoal,
  "input-element-02": $color-black,
  "input-element-03": $color-middlegray-2,
  "input-element-04": $color-charcoal,
);

//$cssVariablesPrint: (
////css Variables für Print
//);

//Cross-browser css variables support
@mixin make-css-variables($cssVariables) {
  @each $name, $value in $cssVariables {
    --#{$name}: #{$value};
  }
}

@mixin var($property, $name, $cssVariables: $cssVariablesScreen) {
  @if map-has-key($cssVariables, $name) {
    #{$property}: map-get($cssVariables, $name);
  } @else {
    @warn "Missing CSS variable: #{$name}";
  }

  #{$property}: var(--#{$name});
}

:root {
  @media screen {
    @include make-css-variables($cssVariablesScreen);
  }

  //@media (prefers-color-scheme: dark) {
  //  @include make-css-variables($cssVariablesDarkMode);
  //  }

  //@media print {
  //  @each $name, $value in $cssVariablesPrint {
  //    --#{$name}: #{$value};
  //  }
}
