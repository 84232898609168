// Text für Screen-Reader im Viewport verstecken.
//
// .example {
//   @include screen-reader-text;
// }
@mixin screen-reader-text() {
  clip: rect(1px, 1px, 1px, 1px);
  width: 1px;
  height: 1px;
  overflow: hidden;
  position: absolute;
}
