.plus-logo {
  &,
  svg {
    display: inline-flex;
    margin: 0 auto;
  }

  &--small {
    width: 78px;
    height: auto;
  }
}
