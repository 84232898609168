.horizontal-teaser {
  list-style-type: none;
  height: 100%;

  &__article {
    @include var(color, text-02-color);
    @include var(background-color, bg-elements-01);

    display: flex;
    position: relative;
    border-radius: $border-radius-m;
    overflow: hidden;
    list-style-type: none;
    margin-bottom: 10px;
    flex-wrap: nowrap;
    border-bottom: 0;

    @include breakpoint(md) {
      min-width: unset;

      &:hover {
        @include transition(box-shadow 0.2s ease);

        box-shadow: $shadow-input-search;
      }
    }
  }

  &__image-wrapper {
    flex: 1 1 155px;
    position: relative;
    overflow: hidden;
  }

  &__image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    object-fit: cover;
  }

  &__content {
    padding: 15px;
    flex: 0 1 100%;

    @include breakpoint(md) {
      padding: 20px;
    }
  }

  &__description {
    @include var(color, text-01-color);

    font-weight: 400;
    font-size: $font-text-xs;
    line-height: $font-text-sm;
    margin-bottom: 15px;

    @include breakpoint(md) {
      font-size: $font-text-base;
      line-height: $font-text-s;
    }
  }

  &__source {
    @include var(color, text-03-color);

    font-weight: 400;
    font-size: $font-text-xs;
    margin-bottom: 2px;

    @include breakpoint(md) {
      font-size: $font-text-base;
    }
  }

  &__source:last-child {
    margin-bottom: 0;
  }

  &__headline {
    @include var(color, text-02-color);

    font-size: $font-text-base;
    font-weight: 500;
    margin-bottom: 5px;

    @include breakpoint(md) {
      font-size: $font-text-mbase;
      line-height: $font-text-m;
    }

    &--news {
      margin-bottom: 10px;
    }
  }

  &__published {
    @include var(color, text-05-color);

    font-size: $font-text-xxs;
    font-weight: 400;

    @include breakpoint(md) {
      font-size: $font-text-xs;
    }
  }

  &__link {
    @include var(color, text-02-color);

    text-decoration: none;

    &::before {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
