.emoji-panel {
  display: inline-flex;
  background-color: $color-snow;
  padding: 2px;
  border-radius: $border-radius-xs;
  flex: 0 0 auto;
  margin-right: 0.5rem;
  width: 22px;
  height: 22px;
  align-items: center;
  justify-content: center;

  &--is-big {
    font-size: 1.4rem;
    padding: 10px;
    width: 32px;
    height: 32px;
    border-radius: $border-radius-ssm;
  }
}
