$imageTeaser: ".image-teaser";

.image-teaser {
  &__link {
    display: block;
  }

  &__wrapper {
    position: relative;
    overflow: hidden;
    border-radius: $border-radius-sm;
  }

  &__image {
    display: block;
    max-width: none;
    height: 200px;
    width: auto;
  }

  &__image-meta {
    position: absolute;
    bottom: 0.5rem;
    right: 0.5rem;
  }

  &__image-meta-info {
    border-radius: $border-radius-s;
    background-color: rgba(255, 255, 255, 0.9);
    color: $color-charcoal-dark;
    padding: 0.25rem;
    font-size: $font-text-xxs;
    display: block;
  }
}
