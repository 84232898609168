.image-results {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;

  &__fill {
    flex-basis: 0;
    flex-shrink: 1;
    flex-grow: 1;
  }

  &__divider {
    display: none;
    width: 100%;
    flex-basis: 100%;
    border: 0;

    @include breakpoint(md-max) {
      &:nth-of-type(2n) {
        display: block;
      }
    }

    @include breakpoint(md) {
      &:nth-of-type(3n) {
        display: block;
      }
    }

    @include breakpoint(lg) {
      &:nth-of-type(3n) {
        display: none;
      }

      &:nth-of-type(4n) {
        display: block;
      }
    }

    @include breakpoint(xl) {
      &:nth-of-type(4n) {
        display: none;
      }

      &:nth-of-type(5n) {
        display: block;
      }
    }

    @include breakpoint(xxl) {
      &:nth-of-type(5n) {
        display: none;
      }

      &:nth-of-type(6n) {
        display: block;
      }
    }
  }
}
