$form: ".form";

.form {
  position: relative;
  margin: 2rem auto;

  &--center {
    text-align: center;
  }

  &--login {
    max-width: 450px;
    margin: 0 auto;
  }

  &__errors {
    font-weight: 300;
    background-color: rgba($color-red, 0.2);
    padding: 1rem;
    border-left: 5px solid $color-red;
    margin: 2rem auto;
  }

  &__fieldset {
    position: relative;
    display: block;
    margin-bottom: 1rem;

    &--inline {
      display: flex;
      margin: 0;
    }
  }

  &__field-action {
    @include focus-outline-style();

    position: absolute;
    top: 4px;
    right: 4px;
    border-radius: 50%;
    background-color: transparent;
    border: 0;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    #{$form}__field-icon {
      svg {
        display: block;
      }
    }

    #{$form}__field-icon.on {
      display: none;
    }

    #{$form}__field-icon.off {
      display: block;
    }

    &.on #{$form}__field-icon {
      &.on {
        display: block;
      }

      &.off {
        display: none;
      }
    }
  }

  input[type="email"],
  input[type="text"],
  input[type="password"] {
    @include focus-outline-style();

    background: $color-white;
    border: 1px solid $color-lightgrey-1;
    box-sizing: border-box;
    box-shadow: $shadow-searchfield;
    border-radius: 100px;
    height: 56px;
    width: 100%;
    padding: 0 2rem;
  }

  input[type="submit"],
  button[type="submit"] {
    @include focus-outline-style();

    margin: 0 auto;
    border-radius: 50%;
    background: $color-white;
    border: 1px solid $color-lightgrey-1;
    box-shadow: $shadow-searchfield;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  &__choices {
    margin-top: 1rem;
  }

  &--inline {
    #{$form}__field-action {
      right: 60px;
    }

    #{$form}__fieldset--has-field-action input[type="email"],
    #{$form}__fieldset--has-field-action input[type="text"],
    #{$form}__fieldset--has-field-action input[type="password"] {
      padding: 0 calc(108px + 0.5rem) 0 2rem;
    }

    input[type="submit"],
    button[type="submit"] {
      position: absolute;
      top: 4px;
      right: 4px;
      border-radius: 50%;
      background: $color-white;
      border: 1px solid $color-lightgrey-1;
      box-shadow: $shadow-searchfield;
      width: 48px;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
}
