.vertical-divider {
  width: 1px;
  height: 2rem;
  background-color: $color-lightgrey-1;
  margin: 1rem auto;

  @include breakpoint(md) {
    margin: 2rem auto;
  }
}
