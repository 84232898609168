.search-results {
  margin: 2rem 0;

  @include breakpoint(lg) {
    margin: 2rem 4rem;
  }

  &__wrapper {
    margin-bottom: 4rem;
  }

  &__container {
    &--related-queries {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 0.625rem;
    }
  }

  &__container--images &__item {
    flex: 0 1 50%;
    width: 50%;
    height: auto;
    display: flex;
    align-items: center;

    @include breakpoint(md) {
      flex: 0 1 calc(100% / 3);
      width: calc(100% / 3);
      height: auto;
    }

    @include breakpoint(lg) {
      flex: 0 1 25%;
      width: 25%;
      height: auto;
    }

    @include breakpoint(xl) {
      flex: 0 1 20%;
      width: 20%;
      height: auto;
    }
  }
}
