.lp-teaser {
  $teaser: &;

  position: relative;
  width: 100%;
  height: 100%;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include breakpoint(lg) {
    font-size: 1.375rem;
  }

  p {
    margin-bottom: 0;
  }

  &__icon {
    &,
    svg {
      display: block;
      width: 48px;
      height: 48px;
      margin-bottom: 0.5rem;

      @include breakpoint(sm) {
        width: 78px;
        height: 78px;
      }
    }
  }

  &__headline {
    margin-bottom: 1rem;
    font-size: 1.375rem;
    line-height: 1.2;
    color: $color-purple;

    @include breakpoint(md) {
      font-size: 1.875rem;
    }
  }

  &__content {
    position: relative;
    padding: 1rem 1rem 0 1rem;
    flex: 0 1 50%;
    z-index: 10;

    @include breakpoint(sm) {
      padding: 2rem 2rem 0 2rem;
    }
  }

  &__decoration {
    position: relative;
    z-index: 10;
  }

  &--theme {
    color: $color-purple;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      border-radius: $border-radius-sm;
      overflow: hidden;
    }
  }

  &--theme-simple {
    text-align: center;
    color: $color-charcoal-dark;
    font-size: 1rem;
    line-height: 1.4;

    #{$teaser}__headline {
      font-size: 1.4rem;
      color: $color-charcoal-dark;
    }

    &::before {
      background-color: $color-snow;
    }
  }

  &--theme-grey {
    &::before {
      background-color: $color-snow;
    }
  }

  &--theme-blue {
    &::before {
      background-color: $color-dimblue;
    }
  }

  &--fullwidth {
    @include breakpoint(sm) {
      display: flex;
    }

    @include breakpoint(lg) {
      min-height: 440px;
    }
  }

  &--fullwidth &__content {
    @include breakpoint(sm) {
      padding: 2rem 0 2rem 2rem;
      flex: 0 1 50%;
      width: 50%;
    }
  }

  &--fullwidth &__decoration {
    @include breakpoint(sm) {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 50%;
      height: 100%;

      figure,
      picture {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;

        img {
          position: absolute;
          bottom: 0;
          right: 0;
          height: auto;
          max-height: 100%;
          width: auto;
          max-width: none;
        }
      }
    }

    @include breakpoint(lg) {
      width: 50%;
      height: 110%;

      figure {
        justify-content: flex-end;
      }
    }
  }
}
