.search-features {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;

  &__wrapper {
    max-width: $container-search-features;
    display: flex;
    flex-direction: row;
    margin: 2rem 0;

    @include breakpoint(sm) {
      margin: 4rem 0;
    }
  }

  &__image-wrapper {
    min-width: 50px;
    margin-right: 10px;
    padding-top: calc(#{$font-h2} + 15px);

    @include breakpoint(md) {
      margin-right: 20px;
      min-width: 100px;
      padding-top: calc(#{$font-h2} + 20px);
    }

    @include breakpoint(md) {
      margin-right: 30px;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__topic {
    @include var(color, text-02-color);

    font-weight: 400;
    font-size: $font-h3;
    margin-bottom: 10px;

    @include breakpoint(md) {
      font-size: $font-h2;
    }
  }

  &__text {
    @include var(color, text-01-color);

    font-weight: 400;
    line-height: 150%;
    font-size: $font-text-base;

    @include breakpoint(md) {
      font-size: $font-text-mbase;
    }
  }
}
