.mobile-show {
  display: none !important;
}

/* Smartphone Portrait and Landscape */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .mobile-show {
    display: flex !important;
  }
}
