@font-face {
  font-family: "Walsheim Pro";
  src:
    url("../../fonts/GT-Walsheim-Pro-Regular.woff2") format("woff2"),
    url("../../fonts/GT-Walsheim-Pro-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Walsheim Pro";
  src:
    url("../../fonts/GT-Walsheim-Pro-Light.woff2") format("woff2"),
    url("../../fonts/GT-Walsheim-Pro-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Walsheim Pro";
  src:
    url("../../fonts/GT-Walsheim-Pro-Medium.woff2") format("woff2"),
    url("../../fonts/GT-Walsheim-Pro-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Walsheim Pro";
  src:
    url("../../fonts/GT-Walsheim-Pro-Bold.woff2") format("woff2"),
    url("../../fonts/GT-Walsheim-Pro-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Realtime";
  src:
    url("../../fonts/realtime-regular-webfont.woff2") format("woff2"),
    url("../../fonts/realtime-regular-webfont.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
