$widget: ".widget-weather";

.widget-weather {
  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;

    @include breakpoint(sm) {
      margin-bottom: 35px;
    }
  }

  &__header-topic {
    font-size: $font-text-base;
    font-weight: 500;
    color: $color-purple;

    @include breakpoint(sm) {
      font-size: $font-text-s;
    }
  }

  &__header-description {
    font-size: $font-text-base;
    font-weight: 300;
    color: $color-charcoal-dark;

    @include breakpoint(sm) {
      font-size: $font-text-s;
    }
  }

  &__header-details {
    display: flex;
    position: relative;
  }

  &__number {
    font-size: 2.5rem;
    z-index: 1;
    font-weight: 300;
    margin-bottom: 0;

    @include breakpoint(sm) {
      font-size: 2.875rem;
    }
  }

  &__symbol {
    display: block;
    position: relative;
    z-index: 1;
    margin: 0 auto;

    &,
    svg {
      width: 48px;
      height: 48px;

      @include breakpoint(sm) {
        width: 54px;
        height: 54px;
      }
    }
  }

  &__content {
    position: relative;
    margin-left: -20px;
    margin-right: -20px;
    z-index: 10;

    &::after {
      content: "";
      position: absolute;
      right: -1px;
      top: 0;
      height: 100%;
      width: 20%;
      background: $weather-gradient-right;
      z-index: 20;

      @include breakpoint(sm) {
        width: 10%;
      }

      @include breakpoint(md) {
        display: none;
      }
    }
  }

  &__weekday-card {
    @include var(background-color, bg-elements-01);

    position: relative;
    padding: 10px 20px;
    border-radius: $border-radius-m;
    margin-right: 10px;
    text-align: center;
    width: 100px;

    @include breakpoint(sm) {
      width: 135px;
    }

    @include breakpoint(md) {
      width: auto;
    }

    @include breakpoint(md) {
      border-radius: $border-radius-s;
      padding: 10px 23px;
    }

    &:last-of-type {
      margin-right: 0;
    }

    &:not(:last-of-type)::after {
      content: "";
      display: block;
      position: absolute;
      left: 100%;
      top: 50%;
      margin-top: -0.75rem;
      background-color: $color-lightgrey-1;
      width: 2px;
      height: 1.5rem;
      border-radius: 10px;
    }

    #{$widget}__symbol {
      margin-bottom: 0.5rem;
    }
  }

  &__day {
    font-size: $font-text-base;
    font-weight: 500;
    margin-bottom: 5px;

    @include breakpoint(md) {
      margin-bottom: 12px;
    }
  }

  &__max-temperature {
    margin-bottom: 5px;
  }
}
