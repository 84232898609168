.new-form {
  &__fieldset {
    & + & {
      margin-top: 1rem;
    }

    &--inline {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      grid-gap: 1rem;

      @include breakpoint(sm) {
        flex-direction: row;

        & > * {
          flex: 1 0 auto;
        }

        button {
          flex: 0 1 auto;
        }
      }
    }
  }

  &__input {
    background: $color-offwhite;
    border: 1px solid $color-lightgrey-4;
    border-radius: $border-radius-ssm;
    padding: 0 1rem;
    height: 48px;

    &.has-error {
      border-color: $color-red;
    }
  }

  &__errors {
    background-color: rgba($color-red, 0.2);
    padding: 1rem;
    border-left: 5px solid $color-red;
    margin: 2rem auto;

    &.is-small {
      margin: 1rem auto;
    }
  }
}
