.settings-payment-wrapper {
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;

  @include breakpoint(sm-max) {
    flex-direction: column;
    text-align: center;
  }
}

.settings-payment {
  &__boxes {
    @include list-reset;

    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
  }

  &__box {
    background: $color-snow;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1), 0 1px 1px rgba(0, 0, 0, 0.06);
    border-radius: 8px;
    padding: 0.5rem;
    font-size: 13px;
    min-width: 96px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__abo {
    display: block;
    text-align: center;
    color: $color-black;
    margin-bottom: 1rem;
  }

  &__price {
    display: block;
    font-weight: 700;
    font-size: 20px;
    line-height: 125%;
    white-space: nowrap;
  }
}
