.cms-options {
  background-color: #f3f3f3;
  padding: 80px 20px 120px;
  display: flex;
  flex-direction: column;

  &__text-wrapper {
    width: 100%;
    margin: 0 auto;
    max-width: 566px;
    margin-bottom: 60px;
  }

  &__blink {
    position: relative;

    &::after {
      position: absolute;
      content: "";
      right: -4px;
      bottom: 0;
      height: 100%;
      width: 1px;
      background: #000000;
      animation: blinking 1s infinite;
    }
  }
}

@keyframes blinking {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
