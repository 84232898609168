.lp-button {
  $button: &;

  @include transition(all 0.3s ease);

  display: inline-flex;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  line-height: 1;
  font-weight: 500;
  border-radius: $border-radius-xl;
  cursor: pointer;
  color: $color-purple;
  align-items: center;

  &:hover {
    text-decoration: none;
    transform: translateY(-2px);
    box-shadow: $shadow-input-search;
  }

  &:focus,
  &:active {
    text-decoration: none;
  }

  &:disabled {
    opacity: 0.6;
  }

  &--small {
    font-size: 0.8rem;
    padding: 0.55rem 1rem;
  }

  &--filled {
    background-color: $color-purple;
    color: $color-white;

    svg {
      fill: $color-white;
    }

    &:hover,
    &:active,
    &:focus {
      background-color: darken($color-purple, 0.01);
      color: $color-white;
    }
  }

  &--white {
    background-color: $color-white;
    color: $color-darkblue;

    svg {
      fill: $color-white;
    }

    &:hover,
    &:active,
    &:focus {
      background-color: darken($color-white, 0.01);
      color: $color-darkblue;
    }
  }

  &--transparent {
    background-color: transparent;
    color: $color-purple;

    &:hover,
    &:active,
    &:focus {
      color: $color-grape;
    }
  }

  &--sky {
    color: $color-blue;
  }

  &#{$button}--sky#{$button}--filled {
    background-color: $color-blue;
    color: $color-white;

    svg {
      fill: $color-white;
    }

    &:hover,
    &:active,
    &:focus {
      background-color: darken($color-blue, 0.01);
      color: $color-white;
    }
  }

  &__icon {
    display: block;
    width: 24px;
    height: 24px;
  }

  &__text + &__icon,
  &__icon + &__text {
    margin-left: 0.5rem;
  }
}
