.list {
  position: relative;
  font-size: $font-text-s;
  font-weight: 300;

  @include breakpoint(sm) {
    font-size: $font-text-sm;
  }

  @include breakpoint(md) {
    font-size: $font-text-m;
  }

  &__item {
    margin-left: 20px;
    margin-bottom: 20px;

    &:last-of-type {
      margin-bottom: 0;
    }

    &--tall {
      margin-bottom: 50px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}
