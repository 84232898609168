.internal-navbar {
  margin-bottom: 0;

  &__list {
    white-space: nowrap;
  }

  &__list-item {
    display: inline-block;
    margin-right: 20px;

    &:last-of-type {
      margin-right: 0;
    }
  }

  &__bubble {
    @include var(border-color, text-02-color);

    border-style: solid;
    border-width: 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1px;
    border-radius: $border-radius-m;
    min-width: 17px;
    margin-left: 5px;
  }

  &__bubble-number {
    @include var(color, text-02-color);

    font-size: $font-text-xxs;
  }

  &__link {
    @include var(color, text-02-color);

    display: inline-flex;
    align-items: center;
    position: relative;
    padding-bottom: 25px;
    opacity: 0.4;
    text-transform: uppercase;
    font-size: $font-text-xs;
    border-bottom: 2px solid transparent;
    height: 40px;

    &:hover {
      @include var(color, text-02-color);

      opacity: 1;
    }

    &.active {
      @include var(color, text-02-color);
      @include var(border-color, text-02-color);

      border-bottom-style: solid;
      border-bottom-width: 2px;
      opacity: 1;

      & > .internal-navbar__bubble {
        @include var(background-color, bg-elements-04);

        border: 1px solid transparent;
      }

      & .internal-navbar__bubble-number {
        @include var(color, text-06-color);
      }
    }
  }
}
