.icon {
  display: inline-block;
  margin-right: 15px;
  width: 35px;

  @include breakpoint(md) {
    width: auto;
  }

  &--small {
    width: 14px;
    height: 14px;
    margin: 0;

    svg {
      display: block;
      width: 14px;
      height: 14px;
    }
  }

  &--medium {
    width: 24px;
    height: 24px;
    margin: 0;

    svg {
      display: block;
      width: 24px;
      height: 24px;
    }
  }
}
