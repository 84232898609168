.settings-result-preferences {
  $this: &;

  @include list-reset;

  margin-top: 1rem;

  &__item {
    border-top: 1px solid $color-lightgrey-3;
    padding: 1rem 0;
    position: relative;
    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
    align-items: center;

    &.is-disabled {
      opacity: 0.6;
      pointer-events: none;
    }

    svg {
      display: block;
      flex: 0 1 auto;
    }
  }

  &__settings {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;

    &-upvote {
      svg path {
        stroke: $color-green;
      }
    }

    &-downvote {
      svg path {
        stroke: $color-red;
      }
    }

    &-toggle {
      background-color: transparent;
      border: 0;
      cursor: pointer;
      padding: 0.25rem;

      #{$this}__settings:hover & {
        background-color: $color-snow;
        border-radius: $border-radius-ssm;

        svg circle,
        svg path {
          stroke: $color-purple;
        }
      }

      svg {
        display: block;
      }
    }

    &-dropdown {
      position: absolute;
      visibility: hidden;
      pointer-events: none;
      z-index: $z-index-topbar;
      top: 100%;
      right: 0;
    }

    &-dropdown:hover,
    &-toggle:hover + &-dropdown {
      visibility: visible;
      pointer-events: all;
    }
  }
}
