.lp-topline {
  display: block;
  margin-bottom: 0;
  color: $color-middlegray;

  &--has-margin {
    margin-bottom: 1rem;
  }
}

.lp-headline {
  margin-bottom: 1rem;
  color: $color-purple;
  line-height: 1.4;
  letter-spacing: -2px;

  &--kicker {
    text-align: center;
    font-size: 1.875rem;
    line-height: 1;
    margin-top: 2rem;

    @include breakpoint(sm) {
      font-size: 4rem;
    }

    @include breakpoint(lg) {
      margin-top: 5rem;
    }

    .strike {
      position: relative;
      text-decoration: none;
      font-weight: 300;

      &::after {
        content: "";
        width: 100%;
        height: 4px;
        border-radius: 4px;
        background-color: $color-salmon;
        position: absolute;
        top: 50%;
        left: 0;
        transform: rotate(-2.55deg);
      }
    }

    strong {
      color: $color-salmon;
    }
  }

  &--1 {
    font-size: 1.8rem;
    line-height: 1;

    @include breakpoint(md) {
      font-size: 2.5rem;
    }
  }

  &--2 {
    font-size: 1.75rem;
  }

  &--3 {
    margin-top: 2rem;
    color: $color-charcoal-dark;
    font-size: 1.375rem;
  }

  &--4 {
    margin-top: 2rem;
    color: $color-charcoal-dark;
    font-size: 1.25rem;
  }
}
