// 1 - Settings
@import "1_settings/sizing";
@import "1_settings/colors";
@import "1_settings/layout";
@import "1_settings/shadow";
@import "1_settings/border-radius";
@import "1_settings/gradient";
@import "1_settings/fonts";
@import "1_settings/icons";

// 2 - Tools
// General
@import "2_tools/general/unitless-sizing";
@import "2_tools/general/breakpoints";

// Spacing
@import "2_tools/spacing/margin";
@import "2_tools/spacing/padding";

// Resets
@import "2_tools/resets/button-reset";
@import "2_tools/resets/link-reset";
@import "2_tools/resets/list-reset";

// Helpers
@import "2_tools/helpers/aspect-ratio";
@import "2_tools/helpers/clearfix";
@import "2_tools/helpers/screen-reader-text";
@import "2_tools/helpers/select-last-row";
@import "2_tools/helpers/triangle";
@import "2_tools/helpers/transition";
@import "2_tools/helpers/mobile-show";
@import "2_tools/helpers/mobile-hide";
@import "2_tools/helpers/focus-style";

// 3 - Generic
@import "../../node_modules/normalize.css/normalize";
@import "3_generic/box-sizing";
@import "3_generic/reset";
@import "3_generic/font";
@import "3_generic/flow";

// 4 - Elements
@import "4_elements/page";
@import "4_elements/image";
@import "4_elements/link";
@import "4_elements/address";
@import "4_elements/button";
@import "4_elements/small";

// 5 - Objects
@import "5_objects/page-wrapper";
@import "5_objects/container";

// 6 - Components
//node modules
@import "../../node_modules/swiper/swiper";
@import "../../node_modules/@tarekraafat/autocomplete.js/dist/css/autoComplete.css";

//layout
@import "6_components/layout/navbar";
@import "6_components/layout/toolbar";
@import "6_components/layout/logo";
@import "6_components/layout/plus-logo";
@import "6_components/layout/section";
@import "6_components/layout/footer";
@import "6_components/layout/header";

// elements
@import "6_components/elements/icon";
@import "6_components/elements/headline";
@import "6_components/elements/text";
@import "6_components/elements/list";
@import "6_components/elements/button";
@import "6_components/elements/link";
@import "6_components/elements/figure";
@import "6_components/elements/alert";

//components
@import "6_components/carousel";

//form
@import "6_components/form/form";
@import "6_components/form/form-switch";
@import "6_components/form/form-select";
@import "6_components/form/form-checkbox";
@import "6_components/form/new-form";

//search results page
@import "6_components/search/search-results";
@import "6_components/search/image-results";
@import "6_components/search/result-section";
@import "6_components/search/video-results";
@import "6_components/search/search-bar";
@import "6_components/search/autocomplete";
@import "6_components/search/page-border";
@import "6_components/internal-navbar";
@import "6_components/related-query";
@import "6_components/image-slider";
@import "6_components/gradient-wrapper";
@import "6_components/pagination";
@import "6_components/load-more";
@import "6_components/search-cta";
@import "6_components/elements/vertical-divider";
@import "6_components/hero";
@import "6_components/pills";
@import "6_components/polelinks";
@import "6_components/panel";
@import "6_components/emoji-panel";
@import "6_components/settings";
@import "6_components/settings-payment";
@import "6_components/settings-result-preferences";
@import "6_components/inactive-wrapper";

//widgets
@import "6_components/widgets/widget";
@import "6_components/widgets/widget-weather";
@import "6_components/widgets/widget-stock";
@import "6_components/widgets/widget-lottery";
@import "6_components/widgets/widget-tellows";

// frontpage
@import "6_components/frontpage/hero-section";
@import "6_components/frontpage/feature-section";
@import "6_components/frontpage/search-hero";
@import "6_components/frontpage/search-features";

// teasers
@import "6_components/teaser/teaser";
@import "6_components/teaser/news-teaser";
@import "6_components/teaser/image-teaser";
@import "6_components/teaser/image-result-teaser";
@import "6_components/teaser/video-teaser";
@import "6_components/teaser/video-result-teaser";
@import "6_components/teaser/horizontal-teaser";
@import "6_components/teaser/square-teaser";
@import "6_components/teaser/entity-teaser";
@import "6_components/teaser/result-preference-settings";
@import "6_components/showMoreText";

// landingpage
@import "6_components/landingpage/lp-base";
@import "6_components/landingpage/lp-container";
@import "6_components/landingpage/lp-section";
@import "6_components/landingpage/lp-typo";
@import "6_components/landingpage/lp-button";
@import "6_components/landingpage/lp-teaser";
@import "6_components/landingpage/lp-icon-teaser";
@import "6_components/landingpage/lp-checklist";
@import "6_components/landingpage/lp-content";
@import "6_components/landingpage/lp-about-us";
@import "6_components/landingpage/lp-logo-decoration";

//dashboard sitesearch landingpage
@import "6_components/dashboard-landingpage/sitesearch-hero";
@import "6_components/dashboard-landingpage/logo-row";
@import "6_components/dashboard-landingpage/image-with-circles";
@import "6_components/dashboard-landingpage/quote-card";
@import "6_components/dashboard-landingpage/cms-options";
@import "6_components/dashboard-landingpage/cms-cards";
@import "6_components/dashboard-landingpage/extensions";
@import "6_components/dashboard-landingpage/german-line";

// 7 - Trumps
@import "7_trumps/visibility";
@import "7_trumps/typography";
@import "7_trumps/flex";
