.video-teaser {
  @include var(background-color, bg-elements-01);

  position: relative;
  flex: 1 1 100%;
  min-width: 220px;
  margin-right: 20px;
  border-radius: $border-radius-m;
  overflow: hidden;
  list-style-type: none;

  @include breakpoint(md) {
    min-width: unset;

    &:hover {
      @include transition(box-shadow 0.2s ease);

      box-shadow: $shadow-input-search;
    }
  }

  &:last-of-type {
    margin-right: 0;
  }

  &__video {
    @include var(color, text-02-color);

    display: flex;
    flex-direction: column;
    justify-content: stretch;
    height: 100%;
    width: 100%;
    border-bottom: 0;
  }

  &__video-wrapper {
    position: relative;
    min-height: 124px;
    max-height: 124px;
    height: 100%;
    overflow: hidden;
  }

  &__image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    height: 100%;
    max-width: none;
    object-fit: cover;
  }

  &__time-wrapper {
    @include var(background-color, bg-elements-08);

    position: absolute;
    bottom: 10px;
    right: 10px;
    padding: 4px 5px;
    border-radius: $border-radius-s;
  }

  &__time {
    @include var(color, text-06-color);

    font-size: $font-text-xs;
  }

  &__content {
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }

  &__link {
    border-bottom: none;
    width: 100%;

    &::before {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &__headline {
    @include var(color, text-02-color);

    font-size: $font-text-mbase;
    font-weight: 500;
    margin-bottom: 10px;

    @include breakpoint(md) {
      font-size: $font-text-mbase;
      line-height: $font-text-m;
    }
  }

  &__source {
    @include var(color, text-03-color);

    font-weight: 400;
    line-height: $font-text-mbase;
    margin-bottom: 2px;
  }

  &__published {
    @include var(color, text-05-color);

    font-size: $font-text-xxs;
    font-weight: 400;
    line-height: $font-text-xs;
  }

  &__details {
    align-self: end;
    width: 100%;
  }
}
