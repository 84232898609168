.search-hero {
  height: 100px;
  min-height: 100vh;/* Fallback für browsers, die keine Custom Properties lesen */
  min-height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 0 1.5rem;
  position: relative;
  padding-bottom: 1.5rem;

  &__wrapper {
    margin: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 735px;
    width: 100%;
    justify-content: space-between;

    @include breakpoint(md) {
      flex-direction: row;
    }
  }

  &__logo {
    display: flex;
    align-items: center;
    margin-bottom: 3rem;

    @include breakpoint(md) {
      margin-bottom: 0;
      margin-right: 3rem;
    }

    svg {
      width: 115px;
      height: auto;
    }
  }

  &__link-list {
    @include list-reset;
  }

  &__link {
    font-size: $font-text-xs;

    @include breakpoint(md) {
      font-size: $font-text-base;
    }

    @include breakpoint(sm-max) {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
  }

  &__input {
    margin-bottom: 1.5rem;
    display: flex;
    align-items: center;
  }

  &__headline {
    @include var(color, text-02-color);

    font-size: $font-text-base;
    font-weight: 400;
    text-align: center;
    margin-bottom: 30px;
    line-height: 130%;

    @include breakpoint(sm) {
      font-size: $font-text-sm;
    }
  }

  &__cta {
    margin: 0 auto 10px;
    text-align: center;
    font-size: $font-text-xs;

    @include breakpoint(md) {
      font-size: $font-text-base;
    }
  }

  &__text-content {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
  }

  &__arrow-down {
    @include transition(all 0.2s ease);

    display: block;
    background-repeat: no-repeat;
    width: 14px;
    height: 8px;
    background-size: contain;
    margin: 0 auto;
    background-image: url("../../../images/icons/icon-arrow-down.svg");
  }

  &__cta-wrapper {
    align-self: center;

    &:hover {
      .search-hero__arrow-down {
        @include transition(all 0.1s ease);

        transform: translateY(5px);
      }
    }
  }

  &__features-list {
    display: flex;
    flex-direction: column;
    list-style: none;
    margin: 0 0 auto;
  }

  &__features-item {
    @include var(color, text-05-color);

    font-size: $font-text-base;
    margin-bottom: 15px;
    position: relative;

    &::before {
      @include var(border-color, border-element-01);

      border-style: solid;
      border-width: 1px;
      display: inline-flex;
      vertical-align: text-bottom;
      content: "";
      position: relative;
      width: 20px;
      height: 20px;
      border-radius: 20px;
      margin-right: 10px;
    }
  }

  &__mobile-button-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}
