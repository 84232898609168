.widget {
  $widget: &;

  padding: 20px;
  border-radius: $border-radius-m;
  background: $color-white;

  &__topic {
    @include var(color, text-02-color);

    font-weight: 500;
    margin-bottom: 1.5rem;
    font-size: $font-text-mbase;

    @include breakpoint(md) {
      font-size: $font-text-sm;
    }

    &.no-margin {
      margin-bottom: 0;
    }
  }

  &__button-wrapper {
    margin-top: 1.5rem;

    & > *:first-child {
      margin-right: 0.625rem;
    }
  }

  &__text {
    line-height: 1.375;
  }

  &__content {
    &--with-image {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 1.5rem;

      @include breakpoint(sm) {
        grid-template-columns: auto 160px;
        grid-gap: 3rem;

        #{$widget}__text {
          display: flex;
          flex-direction: column;
        }

        #{$widget}__button-wrapper {
          margin-top: auto;
        }
      }
    }
  }
}
