.lp-aboutus {
  &-header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;

    @include breakpoint(lg) {
      justify-content: space-between;
    }

    &__logo {
      .logo {
        margin: 0;
      }
    }

    &__cta {
      display: none;

      @include breakpoint(lg) {
        display: flex;
      }

      .lp-button {
        margin: 0;
      }
    }
  }

  &-privacy {
    display: block;

    &__fingerprint {
      margin: 1rem auto;

      @include breakpoint(sm) {
        margin: 1rem;
      }

      &,
      svg {
        width: 64px;
        height: 64px;

        @include breakpoint(sm) {
          width: 96px;
          height: 96px;
        }
      }
    }

    &__list {
      @include list-reset;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      @include breakpoint(sm) {
        flex-direction: row;
      }
    }

    &__item {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0.5rem 0;
      line-height: 1.4;
      color: $color-middlegray-3;

      @include breakpoint(sm) {
        text-align: left;

        &:first-child {
          order: 2;
        }

        &:nth-child(2) {
          order: 1;
        }

        &:last-child {
          order: 3;
        }
      }
    }

    &__icon {
      display: block;
      margin-right: 0.5rem;

      &,
      svg {
        width: 24px;
        height: 24px;
      }
    }
  }

  &-companies {
    @include list-reset;

    margin: 2rem 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas: ". . .";
    grid-column-gap: 0.5rem;
    grid-row-gap: 0.5rem;

    @include breakpoint(sm) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-template-areas: ". . . .";
    }

    @include breakpoint(lg) {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      grid-template-areas: ". . . . .";
    }

    li {
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 1.2;
    }

    &__item {
      display: block;
      border: 1px solid $color-lightgrey-1;
      border-radius: $border-radius-s;

      svg {
        display: block;
        width: 100%;
        height: auto;
      }
    }
  }

  &-playstore {
    display: block;

    svg {
      max-width: 100%;
      height: auto;
    }
  }
}
