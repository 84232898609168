.autosuggest {
  position: relative;
  width: 100%;
  height: 100%;
  text-overflow: ellipsis;
  box-sizing: border-box;
  outline: none;
  z-index: 100;

  &_wrapper {
    position: relative;
    width: 100%;
    height: 100%;
  }

  &__results {
    padding: 1rem 0;
    margin: 0;
    outline: none;
    box-sizing: border-box;
    z-index: 10;
    list-style: none;
    border-top: 1px solid $color-lightgrey-1;
    border-bottom-left-radius: 28px;
    border-bottom-right-radius: 28px;
    overflow: hidden;

    &[hidden],
    &:empty {
      opacity: 0;
    }
  }

  &__result {
    margin: 0;
    padding: 0.5rem 1rem;
    list-style: none;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: all 0.2s ease;
    color: $color-purple;
    font-weight: 500;

    mark {
      font-weight: 300;
      color: $color-purple;
      background-color: transparent;
    }
  }

  &__result::selection {
    color: $color-salmon;
    background-color: rgba($color-white, 0);
  }

  &__result[aria-selected="true"] {
    background-color: rgba($color-salmon, 0.15);
  }

  &__result:hover,
  &__result:active,
  &__result:focus {
    cursor: pointer;
    background-color: $color-lightgrey-3;
  }

  &__result--highlighted {
    color: $color-salmon;
    font-weight: bold;
  }

  &__result--highlighted::selection {
    color: $color-salmon;
    background-color: rgba($color-white, 0);
  }

  &__result--selected {
    cursor: pointer;
    background-color: $color-white;
  }
}
