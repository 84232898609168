.text {
  position: relative;
  font-size: $font-text-sm;
  font-weight: 300;
  line-height: 144%;
  letter-spacing: -0.01em;

  @include breakpoint(sm) {
    font-size: $font-text-s;
  }

  @include breakpoint(md) {
    font-size: $font-text-m;
  }

  p,
  address {
    margin-bottom: 1rem;

    @include breakpoint(md) {
      margin-bottom: 2rem;
    }
  }

  strong {
    font-weight: 500;
  }

  &--hero-home {
    font-family: $font;
    font-size: $font-text-sm;
    text-align: center;
    font-weight: 300;
    max-width: $container-text;
    margin-bottom: 48px;

    @include breakpoint(sm) {
      font-size: $font-text-m;
    }
  }

  &--hero {
    font-family: $font;
    font-size: $font-text-sm;
    text-align: center;
    font-weight: 300;

    @include breakpoint(sm) {
      font-size: $font-text-m;
      margin-bottom: 48px;
    }
  }

  &--paragraph {
    display: block;
    margin-bottom: 2rem;

    @include breakpoint(sm) {
      margin-bottom: 3rem;
    }
  }

  &--overline {
    font-weight: 300;
    font-size: $font-text-xs;
    line-height: $font-text-mbase;
    color: #aaaaaa;
    margin-bottom: 5px;

    @include breakpoint(sm) {
      font-size: $font-text-base;
      margin-bottom: 0;
    }
  }

  &--info {
    font-weight: 400;
    font-size: $font-text-xs;
    line-height: $font-text-xs;

    &,
    a {
      color: $color-middlegray;
    }
  }

  &--block {
    font-weight: 300;
    font-size: $font-text-base;
    line-height: 22px;
    color: #444444;

    @include breakpoint(sm) {
      font-size: $font-text-sm;
      line-height: 28px;
    }
  }

  &--salmon {
    color: $color-salmon;
  }

  &--sky {
    color: $color-sky;
  }

  &--purple {
    &,
    * {
      color: $color-purple;
    }
  }

  &--darkgray {
    color: $color-middlegray-4;
  }

  &--gray {
    color: $color-middlegray;
  }

  &--small {
    font-size: 1rem;
  }

  &--xsmall {
    font-size: 0.8rem;
  }

  &--light {
    font-weight: 300;
  }

  &--center {
    text-align: center;
  }

  &--no-margin {
    margin: 0;
  }
}
