.gradient-wrapper {
  position: relative;
  margin-right: -0.5rem;

  &::after {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 20%;
    background: $slider-gradient;
  }
}
