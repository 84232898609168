.quote-card {
  overflow: hidden;
  max-width: 100vw;
  height: 100%;

  &__container {
    display: flex;
    justify-content: center;
    margin: 120px 20px;
  }

  &__card {
    background: #ffffff;
    max-width: 915px;
    box-shadow: 0 20px 10px -11px rgba(0, 0, 0, 0.1);
    position: relative;
    padding: 50px;

    @include breakpoint(md) {
      display: flex;
      flex-direction: row;
    }

    &::before {
      display: block;
      content: "";
      position: absolute;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      height: 445px;
      width: 445px;
      z-index: -1;
      background-image: url("../../images/kringel-yellow.svg");
      top: -84px;
      left: -108px;

      @include breakpoint(md) {
        top: -33px;
      }
    }
  }

  &__content {
    @include breakpoint(md) {
      width: 70%;
    }
  }

  &__image-wrapper {
    margin-bottom: 15px;

    @include breakpoint(md) {
      width: 30%;
      display: flex;
    }
  }

  &__image {
    height: 25px;
    width: auto;

    @include breakpoint(md) {
      height: 30px;
      margin-top: auto;
    }

    @include breakpoint(lg) {
      height: 38px;
    }
  }

  &__quote {
    font-size: 20px;
    line-height: 32px;
    color: #444444;
    font-weight: 300;
    margin-bottom: 20px;

    @include breakpoint(md) {
      font-size: 25px;
      line-height: 37px;
    }

    @include breakpoint(lg) {
      font-size: 30px;
      line-height: 42px;
    }

    strong {
      font-weight: bold;
    }
  }

  &__author {
    letter-spacing: -3.5%;
    color: #444444;
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;

    @include breakpoint(md) {
      line-height: 28px;
      font-size: 20px;
    }

    @include breakpoint(lg) {
      line-height: 34px;
      font-size: 24px;
    }
  }

  &__info {
    letter-spacing: -3.5%;
    line-height: 20px;
    font-size: 16px;
    color: #999999;
    font-weight: 300;

    @include breakpoint(md) {
      line-height: 28px;
      font-size: 20px;
    }

    @include breakpoint(lg) {
      line-height: 34px;
      font-size: 24px;
    }
  }
}
