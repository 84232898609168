.form-select {
  @include focus-outline-style;

  border: 1.5px solid $color-lightgrey-4;
  border-radius: 8px;
  text-align: left;
  padding: 6px 24px 6px 12px;
  margin-bottom: auto;
  appearance: none;
  background-color: $color-white;
  background-image: url("../../../images/icons/icon-arrow-down.svg");
  background-repeat: no-repeat;
  background-position: right 0.5rem center;
}
