.result-preference-settings {
  margin-top: 0.25rem;
  background-color: $color-white;
  padding: 1.25rem 1rem;
  border-radius: $border-radius-ssm;
  border: 1px solid $color-lightgrey-1;
  font-size: 0.875rem;
  font-weight: 500;
  color: $color-middlegray-4;
  text-align: center;

  &__text {
    color: $color-charcoal;
  }

  & > * + *,
  &__options > * + * {
    margin-top: 0.75rem;
  }

  &__options {
    margin-bottom: 0.5rem;
  }

  &__link {
    color: $color-purple;
    display: inline-flex;
    align-items: center;
    gap: 0.25rem;
    grid-gap: 0.25rem;

    svg {
      width: 16px;
      height: 16px;

      path {
        fill: $color-purple;
        stroke: $color-purple;
      }
    }
  }

  input {
    @include screen-reader-text;
  }

  &__option {
    display: flex;
    align-items: center;
    color: $color-charcoal-dark;
    font-size: 1rem;
    border-radius: 24px;
    padding: 0.5rem 0.75rem;
    border: 1px solid $color-lightgrey-1;
    outline: 1px solid transparent;
    cursor: pointer;
    gap: 0.5rem;
    grid-gap: 0.5rem;
    white-space: nowrap;
    font-weight: 400;

    &:hover,
    input:checked + & {
      border-color: $color-azure;
      outline-color: $color-azure;
      color: $color-azure;

      svg circle,
      svg path {
        stroke: $color-azure;
      }
    }

    input:disabled + &,
    input:disabled + &:hover {
      border-color: $color-middlegray-3;
      outline-color: transparent;
      color: $color-middlegray-3;

      svg circle,
      svg path {
        stroke: $color-middlegray-3;
      }
    }
  }
}
