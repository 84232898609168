.square-teaser {
  list-style-type: none;
  margin-right: 10px;
  max-width: 132px;
  width: 100%;
  flex-shrink: 0;
  position: relative;

  &:last-of-type {
    margin-right: 0;
  }

  &__image-wrapper {
    border-radius: $border-radius-m;
    width: 100%;
    height: 132px;
    margin-bottom: 5px;
    position: relative;
    overflow: hidden;
  }

  &__image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    height: 100%;
    max-width: none;
    object-fit: cover;
  }

  &__link {
    @include var(color, text-02-color);

    margin-bottom: 0;
    border-bottom: none;
    font-size: $font-text-mbase;
    font-weight: 500;

    &::before {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    &:hover {
      color: var(--text-02-color);
    }
  }
}
