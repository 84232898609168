.showMoreText {
  $component: &;

  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;

  &__full-text {
    display: none;
  }

  &__short-text {
    display: block;
  }

  .show {
    #{$component}__full-text {
      display: block;
    }

    #{$component}__short-text {
      display: none;
    }
  }
}

.showMoreTextButton {
  $button: &;

  border-top: 1px solid $color-lightgrey-3;
  border-radius: 0 0 $border-radius-sm $border-radius-sm;

  .showMoreTextButton__more {
    display: block;
    pointer-events: none;
  }

  .showMoreTextButton__less {
    display: none;
    pointer-events: none;
  }

  &.show {
    #{$button}__more {
      display: none;
    }

    #{$button}__less {
      display: block;
    }
  }
}
