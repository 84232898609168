$font: "Walsheim Pro", Arial, sans-serif;
$font-realtime: "Realtime", Arial, sans-serif;

$font-size-base: 16px;

$font-huge: 4rem; // 64px

$font-h1: 3.125rem; // 50px
$font-h1-mobile: 1.875rem; //  30px

$font-h2: 1.875rem; //  30px
$font-h2-mobile: 1.375rem; //  20px

$font-h3: 1.5rem; //  21px
$font-h4: 1.4rem; //  21px

$font-text-m: 1.4rem; //  22.4px
$font-text-s: 1.375rem; //  22px
$font-text-sm: 1.25rem; //  20px
$font-text-mbase: 1.125rem; // 18px
$font-text-base: 1rem; //  16px
$font-text-xs: 0.875rem; //  14px
$font-text-xxs: 0.75rem; //  12px
