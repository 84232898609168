.logo {
  &,
  svg {
    display: inline-flex;
    margin: 0 auto;
  }

  &--big {
    &,
    svg {
      height: auto;
      width: 60px;

      @include breakpoint(sm) {
        width: 90px;
      }
    }
  }

  &--small {
    &,
    svg {
      height: 48px;
      width: auto;

      @include breakpoint(sm) {
        height: 56px;
      }
    }
  }

  &--horizontal {
    &,
    svg {
      height: 48px;
      width: auto;

      @include breakpoint(sm) {
        height: 56px;
      }

      @include breakpoint(md) {
        height: 96px;
      }
    }
  }

  &--center {
    margin: 0 auto;
  }
}
