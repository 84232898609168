.link {
  color: var(--text-02-color);
  border-bottom: 0;
  position: relative;

  &:hover {
    color: var(--text-02-color);
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &--suggestion {
    @include transition(all 0.1s ease);
    @include var(color, text-02-color);
    @include var(background-color, bg-suggestion-link);

    position: relative;
    padding: 8px 16px;
    font-weight: 400;
    border-radius: $border-radius-l;
    white-space: nowrap;

    &:hover {
      @include var(color, text-02-color);
      @include var(background-color, bg-suggestion-link-hover);
    }
  }

  &--cta {
    @include transition(all 0.3s ease);
    @include var(background-color, bg-elements-06);

    color: white;
    font-weight: bold;
    padding: 18px;
    border-radius: $border-radius-m;
    position: relative;
    max-width: 315px;
    width: 100%;
    word-break: break-word;
    text-align: center;
    hyphens: inherit;

    &::before {
      @include transition(all 0.3s ease);
      @include var(background-color, bg-elements-07);

      position: absolute;
      content: "";
      top: -6px;
      left: -5px;
      border-radius: $border-radius-m;
      width: 100%;
      height: 100%;
      z-index: -1;
    }

    &::after {
      @include transition(all 0.3s ease);
      @include var(background-color, bg-elements-04);

      position: absolute;
      content: "";
      top: 6px;
      left: 5px;
      border-radius: $border-radius-m;
      width: 100%;
      height: 100%;
      z-index: -2;
    }

    &:hover {
      @include var(color, text-06-color);

      letter-spacing: 1px;

      &::before,
      &::after {
        top: 0;
        width: 100%;
        height: 100%;
      }

      &::before {
        left: -5px;
      }

      &::after {
        right: 5px;
      }
    }
  }

  &--grey {
    color: $color-middlegray-4;

    &:hover {
      color: $color-purple;
    }
  }

  &--underlined {
    text-decoration: underline;
  }
}
